@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400;0,500;1,400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600;700&display=swap');

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: 'Playfair Display', serif;
}

body,
button,
input,
select,
textarea, html {
    font-family: 'Montserrat', sans-serif;
}


.f-playfair {
    font-family: 'Playfair Display', serif;
}

.f-roboto {
    font-family: 'Montserrat', sans-serif;
}


