@import url(https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400;0,500;1,400&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600;700&display=swap);
html,
body,
p,
ol,
ul,
li,
dl,
dt,
dd,
blockquote,
figure,
fieldset,
legend,
textarea,
pre,
iframe,
hr,
h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0;
    padding: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-size: 100%;
    line-height: 100%;
    font-weight: 400;
    margin: 0;
}

button,
input,
select,
textarea {
    margin: 0;
}

html {
    box-sizing: border-box;
    height: 100%;
}

body {
    min-height: 100%;
    display: flex;
}


*, *::before, *::after {
    box-sizing: inherit;
}

a {
    text-decoration: none;
    cursor: pointer;
}

img,
video {
    height: auto;
    max-width: 100%;
    vertical-align: bottom;
}

iframe {
    border: 0;
}

table {
    border-collapse: collapse;
    border-spacing: 0;
}

.va-top {
    vertical-align: top;
}

.va-bottom {
    vertical-align: bottom;
}

.va-center {
    vertical-align: center;
}


td,
th {
    padding: 0;
}

html {
    background-color: white;
    font-size: 16px;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    min-width: 300px;
    overflow-x: hidden;
    overflow-y: scroll;
    text-rendering: optimizeLegibility;
    -webkit-text-size-adjust: 100%;
    -moz-text-size-adjust: 100%;
    text-size-adjust: 100%;
}

a {
    cursor: pointer;
}

article,
aside,
figure,
footer,
header,
hgroup,
section {
    display: block;
}

code,
pre {
    -moz-osx-font-smoothing: auto;
    -webkit-font-smoothing: auto;
    font-family: monospace;
}


/*Components*/
/*Tables*/
table {
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
}


.checkbox {
    display: inline-block;
    height: 1.5rem;
    width: 1.5rem;
    overflow: hidden;
    vertical-align: middle;
    -webkit-appearance: none;
       -moz-appearance: none;
            appearance: none;
    background-color: white;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    border: 1px solid #1D3745;
}

.checkbox:checked {
    background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTMiIHZpZXdCb3g9IjAgMCAxOCAxMyIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTE3IDFMNiAxMkwxIDciIHN0cm9rZT0iIzFEMzc0NSIgc3Ryb2tlLXdpZHRoPSIyIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4KPC9zdmc+Cg==");
    background-size: 80%;
}

.radio {
    display: inline-block;
    height: 1.5rem;
    width: 1.5rem;
    overflow: hidden;
    vertical-align: middle;
    -webkit-appearance: none;
       -moz-appearance: none;
            appearance: none;
    background-color: white;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    border: 1px solid #1D3745;
    border-radius: 50%;
}

.radio:checked {
    background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIHZpZXdCb3g9IjAgMCAxNiAxNiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHJlY3QgeD0iMC41IiB5PSIwLjUiIHdpZHRoPSIxNSIgaGVpZ2h0PSIxNSIgcng9IjcuNSIgZmlsbD0iIzFEMzc0NSIgc3Ryb2tlPSIjMUQzNzQ1Ii8+Cjwvc3ZnPgo=");
    background-size: 70%;
}

/*Squares*/
.icon {
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    display: inline-flex;
}


/*Text*/
.f-100 {
    font-weight: 100;
}

.f-200 {
    font-weight: 200;
}

.f-300 {
    font-weight: 300;
}

.f-400 {
    font-weight: 400;
}

.f-500 {
    font-weight: 500;
}

.f-600 {
    font-weight: 600;
}

.f-700 {
    font-weight: 700;
}

.f-800 {
    font-weight: 800;
}

.f-900 {
    font-weight: 900;
}

.f-bold {
    font-weight: bold;
}

.f-bolder {
    font-weight: bolder;
}

.f-normal {
    font-weight: normal;
}

.f-lighter {
    font-weight: lighter;
}

.f-light {
    font-weight: light;
}

.f-italic {
    font-style: italic;
}

.f-underline {
    text-decoration: underline;
}

.f-line-through {
    text-decoration: line-through;
}

.f-uppercase {
    text-transform: uppercase;
}

.f-1rem {
    font-size: 1rem;
}

.f-medium {
    font-size: 1.2rem;
}

.f-large {
    font-size: 1.5rem;
}

.f-small {
    font-size: 0.8rem;
}

.lh-80 {
    line-height: 80%;
}

.lh-90 {
    line-height: 90%;
}


.lh-100 {
    line-height: 100%;
}

.lh-120 {
    line-height: 120%;
}

.lh-150 {
    line-height: 150%;
}

.lh-200 {
    line-height: 200%;
}

.letter-1 {
    letter-spacing: 1px;
}

.letter-2 {
    letter-spacing: 2px;
}

.letter-3 {
    letter-spacing: 3px;
}

.letter-4 {
    letter-spacing: 4px;
}

.letter-5 {
    letter-spacing: 5px;
}

.letter-6 {
    letter-spacing: 6px;
}

.letter-1-n {
    letter-spacing: -1px;
}

.letter-2-n {
    letter-spacing: -2px;
}

.letter-3-n {
    letter-spacing: -3px;
}

.letter-4-n {
    letter-spacing: -4px;
}

.letter-5-n {
    letter-spacing: -5px;
}

.letter-6-n {
    letter-spacing: -6px;
}


/*Titles*/

.h0 {
    font-size: 6rem;
}

.h1, h1 {
    font-size: 4rem;
}

.h2, h2 {
    font-size: 2.8rem;
}

.h3, h3 {
    font-size: 2.5rem;
}

.h4, h4 {
    font-size: 2rem;
}

.h5, h5 {
    font-size: 1.6rem;
}

.h6, h6 {
    font-size: 1.2rem;
}

/*Paragraph* */

.left {
    text-align: left;
}

.right {
    text-align: right;
}

.center {
    text-align: center;
}

/*textbox*/
.textbox a:not(.button) {
    text-decoration: underline;
}

.textbox ul, .textbox ol {
    padding-left: 2rem;
    margin-bottom: 1rem;
}


/*display*/

.flex {
    display: flex;
}

.block, .hover-child-block:hover > * {
    display: block;
}

.inline-flex {
    display: inline-flex;
}

.grid {
    display: grid;
}

.hidden {
    display: none;
}

/*position*/
.relative {
    position: relative;
}

.absolute {
    position: absolute;
}

.static {
    position: static;
}

.fixed {
    position: fixed;
}

.sticky {
    position: -webkit-sticky;
    position: sticky;
}

.edges {
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}

.left-auto {
    left: auto;
}

.right-auto {
    right: auto;
}

.top-auto {
    top: auto;
}

.bottom-auto {
    bottom: auto;
}

.top-1-n {
    top: -1rem;
}

.bottom-1-n {
    bottom: -1rem;
}

.left-1-n {
    left: -1rem;
}

.right-1-n {
    right: -1rem;
}

.top-0 {
    top: 0;
}

.bottom-0 {
    bottom: 0;
}

.left-0 {
    left: 0;
}

.right-0 {
    right: 0;
}

.top-quarter {
    top: 0.25rem
}

.bottom-quarter {
    bottom: 0.25rem
}

.left-quarter {
    left: 0.25rem
}

.right-quarter {
    right: 0.25rem
}


.top-half {
    top: 0.5rem
}

.bottom-half {
    bottom: 0.5rem
}

.left-half {
    left: 0.5rem
}

.right-half {
    right: 0.5rem
}


.top-1 {
    top: 1rem;
}

.bottom-1 {
    bottom: 1rem;
}

.left-1 {
    left: 1rem;
}

.right-1 {
    right: 1rem;
}

.top-2 {
    top: 2rem;
}

.bottom-2 {
    bottom: 2rem;
}

.left-2 {
    left: 2rem;
}

.right-2 {
    right: 2rem;
}

.top-3 {
    top: 3rem;
}

.bottom-3 {
    bottom: 3rem;
}

.left-3 {
    left: 3rem;
}

.right-3 {
    right: 3rem;
}

.top-4 {
    top: 4rem;
}

.bottom-4 {
    bottom: 4rem;
}

.left-4 {
    left: 4rem;
}

.right-5 {
    right: 5rem;
}

.top-5 {
    top: 5rem;
}

.bottom-5 {
    bottom: 5rem;
}

.left-5 {
    left: 5rem;
}

.right-5 {
    right: 5rem;
}

.top-6 {
    top: 6rem;
}

.bottom-6 {
    bottom: 6rem;
}

.left-6 {
    left: 6rem;
}

.right-6 {
    right: 6rem;
}

.top-8 {
    top: 8rem;
}

.bottom-8 {
    bottom: 8rem;
}

.left-8 {
    left: 8rem;
}

.right-8 {
    right: 8rem;
}

.top-10 {
    top: 10rem;
}

.bottom-10 {
    bottom: 10rem;
}

.left-10 {
    left: 10rem;
}

.right-10 {
    right: 10rem;
}

.left-6-n {
    left: -6rem;
}

.right-6-n {
    right: -6rem;
}

.left-8-n {
    left: -8rem;
}

.right-8-n {
    right: -8rem;
}

.left-10-n {
    left: -10rem;
}

.right-10-n {
    right: -10rem;
}

.left-12-n {
    left: -12rem;
}

.right-12-n {
    right: -12rem;
}

.left-14-n {
    left: -14rem;
}

.right-14-n {
    right: -14rem;
}


.left-15-n {
    left: -15rem;
}

.right-15-n {
    right: -15rem;
}

.left-16-n {
    left: -16rem;
}

.right-16-n {
    right: -16rem;
}

.left-20-n {
    left: -20rem;
}

.right-20-n {
    right: -20rem;
}


.top-2-n {
    top: -2rem;
}

.bottom-2-n {
    bottom: -2rem;
}


.top-3-n {
    top: -3rem;
}

.bottom-3-n {
    bottom: -3rem;
}


.top-4-n {
    top: -4rem;
}

.bottom-4-n {
    bottom: -4rem;
}


.top-6-n {
    top: -6rem;
}

.bottom-6-n {
    bottom: -6rem;
}

.top-10-n {
    top: -10rem;
}

.bottom-10-n {
    bottom: -10rem;
}

.top-15 {
    top: 15rem;
}

.top-16 {
    top: 16rem;
}


/*Translate*/
.tx-100px {
    transform: translateX(100px);
}

.tx-200px {
    transform: translateX(200px);
}

.tx-300px {
    transform: translateX(300px);
}

.tx-100px-n {
    transform: translateX(-100px);
}

.tx-200px-n {
    transform: translateX(-200px);
}

.tx-300px-n {
    transform: translateX(-200px);
}

/*Transition*/

.t-2 {
    transition: all 0.2s;
}

.t-3 {
    transition: all 0.3s;
}

.t-4 {
    transition: all 0.4s;
}

.t-5 {
    transition: all 0.5s;
}


/*Zindex*/
.z-10 {
    z-index: 10;
}

.z-20 {
    z-index: 20;
}

.z-30 {
    z-index: 30;
}

.z-40 {
    z-index: 40;
}

.z-50 {
    z-index: 50;
}

.z-60 {
    z-index: 60;
}

.z-70 {
    z-index: 70;
}

.z-80 {
    z-index: 80;
}

.z-90 {
    z-index: 90;
}

.z-100 {
    z-index: 100;
}

.z-200 {
    z-index: 200;
}

.z-300 {
    z-index: 300;
}

.z-400 {
    z-index: 400;
}

.z-500 {
    z-index: 500;
}

.z-600 {
    z-index: 600;
}

.z-700 {
    z-index: 700;
}

.z-800 {
    z-index: 800;
}

.z-900 {
    z-index: 900;
}

.z-1000 {
    z-index: 1000;
}


/*layout flex*/

.align-center {
    align-items: center;
}

.align-end {
    align-items: flex-end;
}

.align-start {
    align-items: flex-start;
}

.justify-center {
    justify-content: center;
}

.justify-end {
    justify-content: flex-end;
}

.justify-start {
    justify-content: flex-start;
}

.grows {
    flex-grow: 1;
}

.grows-0 {
    flex-grow: 0;
}

.shrinks {
    flex-shrink: 1;
}

.shrinks-0 {
    flex-shrink: 0;
}

.column {
    flex-basis: 0;
    flex-grow: 1;
    flex-shrink: 1;
}

.direction-column {
    flex-direction: column;
}

.direction-row-reverse {
    flex-direction: row-reverse;
}

.direction-column-reverse {
    flex-direction: column-reverse;
}

.no-wrap {
    flex-wrap: nowrap;
}

.wrap {
    flex-wrap: wrap;
}

.order-1 {
    order: 1;
}

.order-2 {
    order: 2;
}

.order-3 {
    order: 3;
}

.order-4 {
    order: 4;
}

.order-5 {
    order: 5;
}

.order-6 {
    order: 6;
}

.order-7 {
    order: 7;
}

.order-8 {
    order: 8;
}

.order-9 {
    order: 9;
}

.col2 {
    width: 50%;
}

.col3 {
    width: 33.33%;
}

.col4 {
    width: 25%;
}

.col5 {
    width: 20%;
}


/*columns*/

.grid1 {
    display: grid;
    grid-template-columns: repeat(1, minmax(0, 1fr));
}

.grid2 {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
}

.grid3 {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
}

.grid4 {
    display: grid;
    grid-template-columns: repeat(4, minmax(0, 1fr));
}

.grid5 {
    display: grid;
    grid-template-columns: repeat(5, minmax(0, 1fr));
}

.grid6 {
    display: grid;
    grid-template-columns: repeat(6, minmax(0, 1fr));
}

.grid7 {
    display: grid;
    grid-template-columns: repeat(7, minmax(0, 1fr));
}

.grid8 {
    display: grid;
    grid-template-columns: repeat(8, minmax(0, 1fr));
}

.row-span2 {
    grid-row-start: span 2;
}

.col-span2 {
    grid-column-start: span 2;
}

.col-span3 {
    grid-column-start: span 3;
}

.col-span4 {
    grid-column-start: span 4;
}

.col-span5 {
    grid-column-start: span 5;
}

.col-span6 {
    grid-column-start: span 5;
}

.col-span7 {
    grid-column-start: span 5;
}

.col-span8 {
    grid-column-start: span 5;
}

.col-gap-quarter {
    grid-column-gap: 0.25rem;
}

.col-gap-half {
    grid-column-gap: 0.5rem;
}

.col-gap {
    grid-column-gap: 1rem;
}

.col-gap-2 {
    grid-column-gap: 2rem;
}

.col-gap-3 {
    grid-column-gap: 3rem;
}

.col-gap-4 {
    grid-column-gap: 4rem;
}

.col-gap-6 {
    grid-column-gap: 6rem;
}

.col-gap-8 {
    grid-column-gap: 8rem;
}

.row-gap-quarter {
    grid-row-gap: 0.25rem;
}

.row-gap-half {
    grid-row-gap: 0.5rem;
}

.row-gap {
    grid-row-gap: 1rem;
}

.row-gap-2 {
    grid-row-gap: 2rem;
}

.row-gap-3 {
    grid-row-gap: 3rem;
}

.row-gap-4 {
    grid-row-gap: 4rem;
}


/*Sizes*/

.min-w-2400px {
    min-width: 2400px;
}

.min-w-2000px {
    min-width: 2000px;
}

.min-w-1800px {
    min-width: 1800px;
}

.min-w-1600px {
    min-width: 1600px;
}

.min-w-1400px {
    min-width: 1400px;
}

.min-w-1360px {
    min-width: 1360px;
}

.min-w-1200px {
    min-width: 1200px;
}

.min-w-1056px {
    min-width: 1056px;
}

.min-w-940px {
    min-width: 940px;
}

.min-w-800px {
    min-width: 800px;
}

.min-w-700px {
    min-width: 700px;
}

.min-w-600px {
    min-width: 600px;
}

.min-w-400px {
    min-width: 400px;
}

.min-w-300px {
    min-width: 300px;
}

.min-w-200px {
    min-width: 200px;
}

.min-w-100px {
    min-width: 100px;
}

.min-w-0 {
    min-width: 0px;
}

.min-w-100 {
    min-width: 100%;
}

.min-w-90 {
    min-width: 90%;
}

.min-w-80 {
    min-width: 80%;
}

.min-w-70 {
    min-width: 70%;
}

.min-w-60 {
    min-width: 60%;
}

.min-w-50 {
    min-width: 50%;
}


.max-w-2400px {
    max-width: 2400px;
}

.max-w-2000px {
    max-width: 2000px;
}

.max-w-1800px {
    max-width: 1800px;
}

.max-w-1600px {
    max-width: 1600px;
}

.max-w-1400px {
    max-width: 1400px;
}

.max-w-1360px {
    max-width: 1360px;
}

.max-w-1200px {
    max-width: 1200px;
}

.max-w-1056px {
    max-width: 1056px;
}

.max-w-940px {
    max-width: 940px;
}

.max-w-800px {
    max-width: 800px;
}

.max-w-700px {
    max-width: 700px;
}

.max-w-600px {
    max-width: 600px;
}

.max-w-500px {
    max-width: 500px;
}

.max-w-400px {
    max-width: 400px;
}

.max-w-350px {
    max-width: 350px;
}

.max-w-300px {
    max-width: 300px;
}

.max-w-200px {
    max-width: 200px;
}

.max-w-100px {
    max-width: 100px;
}

.max-w-100 {
    max-width: 100%;
}

.max-w-90 {
    max-width: 90%;
}

.max-w-80 {
    max-width: 80%;
}

.max-w-70 {
    max-width: 70%;
}

.max-w-60 {
    max-width: 60%;
}

.max-w-50 {
    max-width: 50%;
}

/*Width*/
.w-0 {
    width: 0;
}

.w-1rem {
    width: 1rem;
}

.w-2rem {
    width: 2rem;
}

.w-3rem {
    width: 3rem;
}

.w-4rem {
    width: 4rem;
}

.w-5rem {
    width: 5rem;
}

.w-6rem {
    width: 6rem;
}

.w-7rem {
    width: 7rem;
}

.w-8rem {
    width: 8rem;
}

.w-10rem {
    width: 10rem;
}

.w-15rem {
    width: 15rem;
}

.w-20rem {
    width: 20rem;
}

.w-100 {
    width: 100%;
}

.w-90 {
    width: 90%;
}

.w-80 {
    width: 80%;
}

.w-70 {
    width: 70%;
}


.w-60 {
    width: 60%;
}


.w-50 {
    width: 50%;
}

.w-40 {
    width: 40%;
}

.w-33 {
    width: 33.33%;
}

.w-30 {
    width: 30%;
}

.w-25 {
    width: 25%;
}

.w-20 {
    width: 20%;
}


.w-10 {
    width: 10%;
}

.w-auto {
    width: auto;
}


.w-10px {
    width: 10px;
}

.w-20px {
    width: 20px;
}

.w-30px {
    width: 30px;
}

.w-40px {
    width: 40px;
}

.w-50px {
    width: 50px;
}

.w-60px {
    width: 60px;
}

.w-70px {
    width: 70px;
}

.w-80px {
    width: 80px;
}

.w-90px {
    width: 90px;
}

.w-100px {
    width: 100px;
}

.w-150px {
    width: 150px;
}

.w-200px {
    width: 200px;
}

.w-250px {
    width: 250px;
}

.w-300px {
    width: 300px;
}

.w-350px {
    width: 350px;
}

.w-400px {
    width: 400px;
}

.w-500px {
    width: 500px;
}

.w-600px {
    width: 600px;
}

.w-800px {
    width: 800px;
}

.w-1000px {
    width: 1000px;
}


/*height*/

.h-auto {
    height: auto;
}

.h-halfrem {
    height: 0.5rem;
}

.h-1rem {
    height: 1rem;
}

.h-1halfrem {
    height: 1.5rem;
}

.h-2rem {
    height: 2rem;
}

.h-2halfrem {
    height: 2.5rem;
}

.h-3rem {
    height: 3rem;
}

.h-3halfrem {
    height: 3.5rem;
}

.h-4rem {
    height: 4rem;
}

.h-4halfrem {
    height: 4.5rem;
}

.h-5rem {
    height: 5rem;
}

.h-6rem {
    height: 6rem;
}

.h-7rem {
    height: 7rem;
}

.h-8rem {
    height: 8rem;
}

.h-10rem {
    height: 10rem;
}

.h-15rem {
    height: 15rem;
}

.h-20rem {
    height: 20rem;
}

.h-10vh {
    height: 10vh;
}

.h-20vh {
    height: 20vh;
}

.h-30vh {
    height: 30vh;
}

.h-40vh {
    height: 40vh;
}

.h-50vh {
    height: 50vh;
}

.h-60vh {
    height: 60vh;
}

.h-70vh {
    height: 70vh;
}

.h-80vh {
    height: 80vh;
}

.h-90vh {
    height: 90vh;
}

.h-100vh {
    height: 100vh;
}

.h-10 {
    height: 10%;
}

.h-20 {
    height: 20%;
}

.h-30 {
    height: 30%;
}

.h-40 {
    height: 40%;
}

.h-50 {
    height: 50%;
}

.h-60 {
    height: 60%;
}

.h-100 {
    height: 100%;
}

.h-110 {
    height: 110%;
}

.h-120 {
    height: 120%;
}

.h-130 {
    height: 130%;
}

.h-140 {
    height: 140%;
}

.h-150 {
    height: 150%;
}

.h-100px {
    height: 100px;
}

.h-150px {
    height: 150px;
}

.h-200px {
    height: 200px;
}

.h-250px {
    height: 250px;
}

.h-300px {
    height: 300px;
}

.h-400px {
    height: 400px;
}

.h-500px {
    height: 500px;
}

.h-600px {
    height: 600px;
}

.h-800px {
    height: 800px;
}

.h-1000px {
    height: 1000px;
}


.min-h-0 {
    min-height: 0;
}

.min-h-100vh {
    min-height: 100vh;
}

.min-h-90vh {
    min-height: 90vh;
}

.min-h-80vh {
    min-height: 80vh;
}

.min-h-70vh {
    min-height: 70vh;
}

.min-h-60vh {
    min-height: 60vh;
}

.min-h-50vh {
    min-height: 50vh;
}

.min-h-40vh {
    min-height: 40vh;
}

.min-h-30vh {
    min-height: 30vh;
}

.min-h-20vh {
    min-height: 20vh;
}

.min-h-10vh {
    min-height: 10vh;
}


.min-h-100px {
    min-height: 100px;
}

.min-h-200px {
    min-height: 200px;
}

.min-h-300px {
    min-height: 300px;
}

.min-h-400px {
    min-height: 400px;
}

.min-h-500px {
    min-height: 500px;
}

.min-h-600px {
    min-height: 600px;
}

.min-h-700px {
    min-height: 700px;
}

.min-h-800px {
    min-height: 800px;
}


.max-h-0 {
    max-height: 0;
}

.max-h-100vh {
    max-height: 100vh;
}

.max-h-90vh {
    max-height: 90vh;
}

.max-h-80vh {
    max-height: 80vh;
}

.max-h-70vh {
    max-height: 70vh;
}

.max-h-60vh {
    max-height: 60vh;
}

.max-h-50vh {
    max-height: 50vh;
}

.max-h-40vh {
    max-height: 40vh;
}

.max-h-30vh {
    max-height: 30vh;
}

.max-h-20vh {
    max-height: 20vh;
}

.max-h-10vh {
    max-height: 10vh;
}


.max-h-100px {
    max-height: 100px;
}

.max-h-200px {
    max-height: 200px;
}

.max-h-300px {
    min-height: 300px;
}

.max-h-400px {
    min-height: 400px;
}

.max-h-500px {
    max-height: 500px;
}

.max-h-600px {
    max-height: 600px;
}

.max-h-700px {
    max-height: 700px;
}

.max-h-800px {
    max-height: 800px;
}


.sq-1rem {
    width: 1rem;
    height: 1rem;
}

.sq-1halfrem {
    width: 1.5rem;
    height: 1.5rem;
}

.sq-2rem {
    width: 2rem;
    height: 2rem;
}

.sq-2halfrem {
    width: 2.5rem;
    height: 2.5rem;
}

.sq-3rem {
    width: 3rem;
    height: 3rem;
}

.sq-3halfrem {
    width: 3.5rem;
    height: 3.5rem;
}


.sq-4rem {
    width: 4rem;
    height: 4rem;
}

.sq-4halfrem {
    width: 4.5rem;
    height: 4.5rem;
}


.sq-5rem {
    width: 5rem;
    height: 5rem;
}

.sq-6rem {
    width: 6rem;
    height: 6rem;
}

.sq-7rem {
    width: 7rem;
    height: 7rem;
}

.sq-8rem {
    width: 8rem;
    height: 8rem;
}

.sq-10rem {
    width: 10rem;
    height: 10rem;
}

.sq-15rem {
    width: 15rem;
    height: 15rem;
}

.sq-20rem {
    width: 20rem;
    height: 20rem;
}

/*Overflows*/

.overflow-y-scroll {
    overflow-y: scroll;
}

.overflow-y-auto {
    overflow-y: auto;
}

.overflow-x-scroll {
    overflow-x: scroll;
}

.overflow-x-hidden {
    overflow-x: hidden;
}

.overflow-y-auto {
    overflow-x: auto;
}

.overflow-hidden {
    overflow: hidden;
}

/*Margins*/
.m-0 {
    margin: 0;
}

.m-auto {
    margin: auto;
}

.mver-auto {
    margin-top: auto;
    margin-bottom: auto;
}

.mhor-auto {
    margin-right: auto;
    margin-left: auto;
}

.m-0 {
    margin: 0;
}

.ml-0 {
    margin-left: 0;
}

.mr-0 {
    margin-right: 0;
}

.mt-0 {
    margin-top: 0;
}

.mb-0 {
    margin-bottom: 0;
}

.m {
    margin: 1rem;
}

.ml {
    margin-left: 1rem;
}

.mr {
    margin-right: 1rem;
}

.mb, .childs-mb > * {
    margin-bottom: 1rem;
}

.mt {
    margin-top: 1rem;
}

.mv {
    margin-bottom: 1rem;
    margin-top: 1rem;
}

.mhor {
    margin-right: 1rem;
    margin-left: 1rem;
}

.mver {
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.m-half {
    margin: 0.5rem;
}

.ml-half {
    margin-left: 0.5rem;
}

.mr-half {
    margin-right: 0.5rem;
}

.mb-half, .childs-mb-half > * {
    margin-bottom: 0.5rem;
}

.mt-half {
    margin-top: 0.5rem;
}

.mv-half {
    margin-bottom: 0.5rem;
    margin-top: 0.5rem;
}

.mhor-half {
    margin-right: 0.5rem;
    margin-left: 0.5rem;
}


.m-quarter {
    margin: 0.25rem;
}

.ml-quarter {
    margin-left: 0.25rem;
}

.mr-quarter {
    margin-right: 0.25rem;
}

.mb-quarter {
    margin-bottom: 0.25rem;
}

.mt-quarter {
    margin-top: 0.25rem;
}

.mver-quarter {
    margin-bottom: 0.25rem;
    margin-top: 0.25rem;
}

.mhor-quarter {
    margin-right: 0.25rem;
    margin-left: 0.25rem;
}


.m-2 {
    margin: 2rem;
}

.ml-2 {
    margin-left: 2rem;
}

.mr-2 {
    margin-right: 2rem;
}

.mb-2 {
    margin-bottom: 2rem;
}

.mt-2 {
    margin-top: 2rem;
}

.mver-2 {
    margin-bottom: 2rem;
    margin-top: 2rem;
}

.mhor-2 {
    margin-right: 2rem;
    margin-left: 2rem;
}

.m-3 {
    margin: 3rem;
}

.ml-3 {
    margin-left: 3rem;
}

.mr-3 {
    margin-right: 3rem;
}

.mb-3 {
    margin-bottom: 3rem;
}

.mb-3-n {
    margin-bottom: -3rem;
}

.mt-3 {
    margin-top: 3rem;
}

.mver-3 {
    margin-bottom: 3rem;
    margin-top: 3rem;
}

.mhor-3 {
    margin-right: 3rem;
    margin-left: 3rem;
}

.m-4 {
    margin: 4rem;
}

.ml-4 {
    margin-left: 4rem;
}

.ml-6 {
    margin-left: 6rem;
}

.ml-8 {
    margin-left: 8rem;
}

.ml-10 {
    margin-left: 10rem;
}

.mr-4 {
    margin-right: 4rem;
}

.mr-6 {
    margin-right: 6rem;
}

.mr-8 {
    margin-right: 8rem;
}

.mb-4 {
    margin-bottom: 4rem;
}


.mb-6 {
    margin-bottom: 6rem;
}

.mb-8 {
    margin-bottom: 8rem;
}

.mb-10 {
    margin-bottom: 10rem;
}

.mt-4 {
    margin-top: 4rem;
}


.mt-6 {
    margin-top: 6rem;
}


.mt-8 {
    margin-top: 8rem;
}


.mt-10 {
    margin-top: 10rem;
}

.mver-4 {
    margin-bottom: 4rem;
    margin-top: 4rem;
}

.mhor-4 {
    margin-right: 4rem;
    margin-left: 4rem;
}

/*Padding*/
.p-0 {
    padding: 0;
}

.pl-0 {
    padding-left: 0;
}

.pr-0 {
    padding-right: 0;
}

.pb-0 {
    padding-bottom: 0;
}

.pt-0 {
    padding-top: 0;
}


.p {
    padding: 1rem;
}

.pl {
    padding-left: 1rem;
}

.pr {
    padding-right: 1rem;
}

.pb {
    padding-bottom: 1rem;
}

.pt {
    padding-top: 1rem;
}

.pver-0 {
    padding-bottom: 0rem;
    padding-top: 0rem;
}

.pver, .childs-pver > * {
    padding-bottom: 1rem;
    padding-top: 1rem;
}

.phor-0 {
    padding-right: 0rem;
    padding-left: 0rem;
}

.phor {
    padding-right: 1rem;
    padding-left: 1rem;
}

.p-half {
    padding: 0.5rem;
}

.pl-half {
    padding-left: 0.5rem;
}

.pr-half {
    padding-right: 0.5rem;
}

.pb-half {
    padding-bottom: 0.5rem;
}

.pt-half {
    padding-top: 0.5rem;
}

.pver-half, .childs-pver-half > * {
    padding-bottom: 0.5rem;
    padding-top: 0.5rem;
}

.phor-half {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
}


.p-quarter {
    padding: 0.25rem;
}

.pl-quarter {
    padding-left: 0.25rem;
}

.pr-quarter {
    padding-right: 0.25rem;
}

.pb-quarter {
    padding-bottom: 0.25rem;
}

.pt-quarter {
    padding-top: 0.25rem;
}

.pver-quarter, .childs-pver-quarter > * {
    padding-bottom: 0.25rem;
    padding-top: 0.25rem;
}

.phor-quarter {
    padding-right: 0.25rem;
    padding-left: 0.25rem;
}


.p-2 {
    padding: 2rem;
}

.pl-2 {
    padding-left: 2rem;
}

.pr-2 {
    padding-right: 2rem;
}

.pb-2 {
    padding-bottom: 2rem;
}

.pt-2 {
    padding-top: 2rem;
}

.pver-2 {
    padding-bottom: 2rem;
    padding-top: 2rem;
}

.phor-2 {
    padding-right: 2rem;
    padding-left: 2rem;
}

.p-3 {
    padding: 3rem;
}

.pl-3 {
    padding-left: 3rem;
}

.pr-3 {
    padding-right: 3rem;
}

.pb-3 {
    padding-bottom: 3rem;
}

.pt-3 {
    padding-top: 3rem;
}

.pver-3 {
    padding-bottom: 3rem;
    padding-top: 3rem;
}

.phor-3 {
    padding-right: 3rem;
    padding-left: 3rem;
}

.p-4 {
    padding: 4rem;
}

.pl-4 {
    padding-left: 4rem;
}

.pr-4 {
    padding-right: 4rem;
}

.pb-4 {
    padding-bottom: 4rem;
}

.pt-4 {
    padding-top: 4rem;
}

.pver-4 {
    padding-bottom: 4rem;
    padding-top: 4rem;
}

.phor-4 {
    padding-right: 4rem;
    padding-left: 4rem;
}

.p-6 {
    padding: 6rem;
}

.pl-6 {
    padding-left: 6rem;
}

.pr-6 {
    padding-right: 6rem;
}

.pb-6 {
    padding-bottom: 6rem;
}

.pt-6 {
    padding-top: 6rem;
}

.pver-6 {
    padding-bottom: 6rem;
    padding-top: 6rem;
}

.phor-6 {
    padding-right: 6rem;
    padding-left: 6rem;
}

.p-8 {
    padding: 8rem;
}

.pl-8 {
    padding-left: 8rem;
}

.pr-8 {
    padding-right: 8rem;
}

.pb-8 {
    padding-bottom: 8rem;
}

.pt-8 {
    padding-top: 8rem;
}

.pver-8 {
    padding-bottom: 8rem;
    padding-top: 8rem;
}

.phor-8 {
    padding-right: 8rem;
    padding-left: 8rem;
}

.pb-100 {
    padding-bottom: 100%;
}

.pb-80 {
    padding-bottom: 80%;
}

.pb-60 {
    padding-bottom: 60%;
}

.pb-40 {
    padding-bottom: 40%;
}


/*objects*/
.obj-cover {
    object-fit: cover;
}

.obj-contain {
    object-fit: contain;
}

.obj-top {
    object-position: top;
}

.obj-bottom {
    object-position: bottom;
}

.obj-left {
    object-position: left;
}

.obj-top-left {
    object-position: left top;
}

.obj-right {
    object-position: right;
}

.bg-contain {
    background-size: contain;
}

.bg-no-repeat {
    background-repeat: no-repeat;
}

.bg-repeat {
    background-repeat: repeat;
}

.bg-cover {
    background-size: cover;
}

.bg-top {
    background-position: top;
}

.bg-bottom {
    background-position: bottom;
}

.bg-left {
    background-position: left;
}

.bg-right {
    background-position: right;
}

.bg-center {
    background-position: center;
}


/*Corners*/
.br-0 {
    border-radius: 0rem;
}

.br-quarter {
    border-radius: 0.25rem;
}

.br-half {
    border-radius: 0.5rem;
}

.br-1half {
    border-radius: 1.5rem;
}

.br {
    border-radius: 1rem;
}

.br-2 {
    border-radius: 2rem;
}

.br-2half {
    border-radius: 2.5rem;
}

.br-3 {
    border-radius: 3rem;
}

.br-4 {
    border-radius: 4rem;
}

.br-8 {
    border-radius: 8rem;
}

.br-50 {
    border-radius: 50%;
}

/*borders*/
.b-0 {
    border: 0px;
}

.b-top-0 {
    border-top: 0px solid;
}

.b-right-0 {
    border-right: 0px solid;
}

.b-bottom-0 {
    border-bottom: 0px solid;
}

.b-left-0 {
    border-left: 0px solid;
}

.b {
    border: 1px solid;
}

.b-right {
    border-right: 1px solid;
}

.b-bottom {
    border-bottom: 1px solid;
}

.b-left {
    border-left: 1px solid;
}

.b-top {
    border-top: 1px solid;
}

.b-2 {
    border: 2px solid;
}

.b-right-2 {
    border-right: 2px solid;
}

.b-bottom-2 {
    border-bottom: 2px solid;
}

.b-left-2 {
    border-left: 2px solid;
}

.b-top-2 {
    border-top: 2px solid;
}


.b-3 {
    border: 3px solid;
}

.b-right-3 {
    border-right: 3px solid;
}

.b-bottom-3 {
    border-bottom: 3px solid;
}

.b-left-3 {
    border-left: 3px solid;
}

.b-top-3 {
    border-top: 3px solid;
}

.b-4 {
    border: 4px solid;
}

.b-right-4 {
    border-right: 4px solid;
}

.b-bottom-4 {
    border-bottom: 4px solid;
}

.b-left-4 {
    border-left: 4px solid;
}

.b-top-4 {
    border-top: 4px solid;
}

.b-5 {
    border: 5px solid;
}

.b-right-5 {
    border-right: 5px solid;
}

.b-bottom-5 {
    border-bottom: 5px solid;
}

.b-left-5 {
    border-left: 5px solid;
}

.b-top-5 {
    border-top: 5px solid;
}


.b-8 {
    border: 8px solid;
}

.b-right-8 {
    border-right: 8px solid;
}

.b-bottom-8 {
    border-bottom: 8px solid;
}

.b-left-8 {
    border-left: 8px solid;
}

.b-top-8 {
    border-top: 8px solid;
}

.focus\:outline-0 {
    outline: 0;
}

/*Opacity*/
.op-0, .hover\:op-0:hover {
    opacity: 0;
}

.op-10, .hover\:op-10:hover {
    opacity: .1;
}

.op-20, .hover\:op-20:hover {
    opacity: .20;
}

.op-30, .hover\:op-30:hover {
    opacity: .30;
}

.op-40, .hover\:op-40:hover {
    opacity: .40;
}

.op-50, .hover\:op-50:hover {
    opacity: .5;
}

.op-60, .hover\:op-60:hover {
    opacity: .60;
}

.op-70, .hover\:op-70:hover {
    opacity: .70;
}

.op-80, .hover\:op-80:hover {
    opacity: .80;
}

.op-90, .hover\:op-90:hover {
    opacity: .90;
}

.op-100, .hover\:op-100:hover {
    opacity: 1;
}


.transition-3 {
    transition: all 0.3s;
}

.transition-4 {
    transition: all 0.4s;
}

.transition-5 {
    transition: all 0.5s;
}

.transition-6 {
    transition: all 0.6s;
}

.cursor-pointer {
    cursor: pointer;
}

.list-none {
    list-style: none;
}

.no-wrap {
    white-space: nowrap;
}

.do-wrap {
    white-space: normal;
}

.break-word {
    overflow-wrap: break-word;
    word-wrap: break-word;
    -webkit-hyphens: auto;
        -ms-hyphens: auto;
            hyphens: auto;
}


.scale-1-1, .hover\:scale-1-1:hover {
    transform: scale(1.1);
}

.scale-1-2, .hover\:scale-1-2:hover {
    transform: scale(1.2);
}


.translate-y, .hover\:translate-y:hover {
    transform: translateY(1rem);
}

.translate-y-half, .hover\:translate-y-half:hover {
    transform: translateY(0.5rem);
}


.translate-y-n, .hover\:translate-y-n:hover {
    transform: translateY(-1rem);
}

.translate-y-half-n, .hover\:translate-y-half-n:hover {
    transform: translateY(-0.5rem);
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: 'Playfair Display', serif;
}

body,
button,
input,
select,
textarea, html {
    font-family: 'Montserrat', sans-serif;
}


.f-playfair {
    font-family: 'Playfair Display', serif;
}

.f-roboto {
    font-family: 'Montserrat', sans-serif;
}



@media (max-width: 1100px) {


    .laptop\:va-top {
        vertical-align: top;
    }

    .laptop\:va-bottom {
        vertical-align: bottom;
    }

    .laptop\:va-center {
        vertical-align: center;
    }


    .laptop\:checkbox {
        display: inline-block;
        height: 1.5rem;
        width: 1.5rem;
        overflow: hidden;
        vertical-align: middle;
        -webkit-appearance: none;
           -moz-appearance: none;
                appearance: none;
        background-color: white;
        background-repeat: no-repeat;
        background-position: 50% 50%;
        border: 1px solid #1D3745;
    }

    .laptop\:checkbox:checked {
        background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTMiIHZpZXdCb3g9IjAgMCAxOCAxMyIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTE3IDFMNiAxMkwxIDciIHN0cm9rZT0iIzFEMzc0NSIgc3Ryb2tlLXdpZHRoPSIyIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4KPC9zdmc+Cg==");
        background-size: 80%;
    }

    .laptop\:radio {
        display: inline-block;
        height: 1.5rem;
        width: 1.5rem;
        overflow: hidden;
        vertical-align: middle;
        -webkit-appearance: none;
           -moz-appearance: none;
                appearance: none;
        background-color: white;
        background-repeat: no-repeat;
        background-position: 50% 50%;
        border: 1px solid #1D3745;
        border-radius: 50%;
    }

    .laptop\:radio:checked {
        background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIHZpZXdCb3g9IjAgMCAxNiAxNiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHJlY3QgeD0iMC41IiB5PSIwLjUiIHdpZHRoPSIxNSIgaGVpZ2h0PSIxNSIgcng9IjcuNSIgZmlsbD0iIzFEMzc0NSIgc3Ryb2tlPSIjMUQzNzQ1Ii8+Cjwvc3ZnPgo=");
        background-size: 70%;
    }

    /*Squares*/
    .laptop\:icon {
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
        display: inline-flex;
    }


    .laptop\:f-100 {
        font-weight: 100;
    }

    .laptop\:f-200 {
        font-weight: 200;
    }

    .laptop\:f-300 {
        font-weight: 300;
    }

    .laptop\:f-400 {
        font-weight: 400;
    }

    .laptop\:f-500 {
        font-weight: 500;
    }

    .laptop\:f-600 {
        font-weight: 600;
    }

    .laptop\:f-700 {
        font-weight: 700;
    }

    .laptop\:f-800 {
        font-weight: 800;
    }

    .laptop\:f-900 {
        font-weight: 900;
    }

    .laptop\:f-bold {
        font-weight: bold;
    }

    .laptop\:f-bolder {
        font-weight: bolder;
    }

    .laptop\:f-normal {
        font-weight: normal;
    }

    .laptop\:f-lighter {
        font-weight: lighter;
    }

    .laptop\:f-light {
        font-weight: light;
    }

    .laptop\:f-italic {
        font-style: italic;
    }

    .laptop\:f-underline {
        text-decoration: underline;
    }

    .laptop\:f-line-through {
        text-decoration: line-through;
    }

    .laptop\:f-uppercase {
        text-transform: uppercase;
    }

    .laptop\:f-1rem {
        font-size: 1rem;
    }

    .laptop\:f-medium {
        font-size: 1.2rem;
    }

    .laptop\:f-large {
        font-size: 1.5rem;
    }

    .laptop\:f-small {
        font-size: 0.8rem;
    }

    .laptop\:lh-80 {
        line-height: 80%;
    }

    .laptop\:lh-90 {
        line-height: 90%;
    }


    .laptop\:lh-100 {
        line-height: 100%;
    }

    .laptop\:lh-120 {
        line-height: 120%;
    }

    .laptop\:lh-150 {
        line-height: 150%;
    }

    .laptop\:lh-200 {
        line-height: 200%;
    }

    .laptop\:letter-1 {
        letter-spacing: 1px;
    }

    .laptop\:letter-2 {
        letter-spacing: 2px;
    }

    .laptop\:letter-3 {
        letter-spacing: 3px;
    }

    .laptop\:letter-4 {
        letter-spacing: 4px;
    }

    .laptop\:letter-5 {
        letter-spacing: 5px;
    }

    .laptop\:letter-6 {
        letter-spacing: 6px;
    }

    .laptop\:letter-1-n {
        letter-spacing: -1px;
    }

    .laptop\:letter-2-n {
        letter-spacing: -2px;
    }

    .laptop\:letter-3-n {
        letter-spacing: -3px;
    }

    .laptop\:letter-4-n {
        letter-spacing: -4px;
    }

    .laptop\:letter-5-n {
        letter-spacing: -5px;
    }

    .laptop\:letter-6-n {
        letter-spacing: -6px;
    }


    /*Titles*/
    .laptop\:h0 {
        font-size: 6rem;
    }

    .laptop\:h1, h1 {
        font-size: 4rem;
    }

    .laptop\:h2, h2 {
        font-size: 2.8rem;
    }

    .laptop\:h3, h3 {
        font-size: 2.5rem;
    }

    .laptop\:h4, h4 {
        font-size: 2rem;
    }

    .laptop\:h5, h5 {
        font-size: 1.6rem;
    }

    .laptop\:h6, h6 {
        font-size: 1.2rem;
    }

    /*Paragraph* */
    .laptop\:left {
        text-align: left;
    }

    .laptop\:right {
        text-align: right;
    }

    .laptop\:center {
        text-align: center;
    }

    /*textbox*/
    .laptop\:textbox a:not(.button) {
        text-decoration: underline;
    }

    .laptop\:textbox ul, .laptop\:textbox ol {
        padding-left: 2rem;
        margin-bottom: 1rem;
    }


    /*display*/
    .laptop\:flex {
        display: flex;
    }

    .laptop\:block, .laptop\:childs-block > * {
        display: block;
    }

    .laptop\:inline-flex {
        display: inline-flex;
    }

    .laptop\:grid {
        display: grid;
    }

    .laptop\:hidden {
        display: none;
    }

    /*position*/
    .laptop\:relative {
        position: relative;
    }

    .laptop\:absolute {
        position: absolute;
    }

    .laptop\:static {
        position: static;
    }

    .laptop\:fixed {
        position: fixed;
    }

    .laptop\:sticky {
        position: -webkit-sticky;
        position: sticky;
    }

    .laptop\:edges {
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
    }

    .laptop\:left-auto {
        left: auto;
    }

    .laptop\:right-auto {
        right: auto;
    }

    .laptop\:top-auto {
        top: auto;
    }

    .laptop\:bottom-auto {
        bottom: auto;
    }

    .laptop\:top-1-n {
        top: -1rem;
    }

    .laptop\:bottom-1-n {
        bottom: -1rem;
    }

    .laptop\:left-1-n {
        left: -1rem;
    }

    .laptop\:right-1-n {
        right: -1rem;
    }

    .laptop\:top-0 {
        top: 0;
    }

    .laptop\:bottom-0 {
        bottom: 0;
    }

    .laptop\:left-0 {
        left: 0;
    }

    .laptop\:right-0 {
        right: 0;
    }

    .laptop\:top-quarter {
        top: 0.25rem
    }

    .laptop\:bottom-quarter {
        bottom: 0.25rem
    }

    .laptop\:left-quarter {
        left: 0.25rem
    }

    .laptop\:right-quarter {
        right: 0.25rem
    }


    .laptop\:top-half {
        top: 0.5rem
    }

    .laptop\:bottom-half {
        bottom: 0.5rem
    }

    .laptop\:left-half {
        left: 0.5rem
    }

    .laptop\:right-half {
        right: 0.5rem
    }


    .laptop\:top-1 {
        top: 1rem;
    }

    .laptop\:bottom-1 {
        bottom: 1rem;
    }

    .laptop\:left-1 {
        left: 1rem;
    }

    .laptop\:right-1 {
        right: 1rem;
    }

    .laptop\:top-2 {
        top: 2rem;
    }

    .laptop\:bottom-2 {
        bottom: 2rem;
    }

    .laptop\:left-2 {
        left: 2rem;
    }

    .laptop\:right-2 {
        right: 2rem;
    }

    .laptop\:top-3 {
        top: 3rem;
    }

    .laptop\:bottom-3 {
        bottom: 3rem;
    }

    .laptop\:left-3 {
        left: 3rem;
    }

    .laptop\:right-3 {
        right: 3rem;
    }

    .laptop\:top-4 {
        top: 4rem;
    }

    .laptop\:bottom-4 {
        bottom: 4rem;
    }

    .laptop\:left-4 {
        left: 4rem;
    }

    .laptop\:right-5 {
        right: 5rem;
    }

    .laptop\:top-5 {
        top: 5rem;
    }

    .laptop\:bottom-5 {
        bottom: 5rem;
    }

    .laptop\:left-5 {
        left: 5rem;
    }

    .laptop\:right-5 {
        right: 5rem;
    }

    .laptop\:top-6 {
        top: 6rem;
    }

    .laptop\:bottom-6 {
        bottom: 6rem;
    }

    .laptop\:left-6 {
        left: 6rem;
    }

    .laptop\:right-6 {
        right: 6rem;
    }

    .laptop\:top-8 {
        top: 8rem;
    }

    .laptop\:bottom-8 {
        bottom: 8rem;
    }

    .laptop\:left-8 {
        left: 8rem;
    }

    .laptop\:right-8 {
        right: 8rem;
    }

    .laptop\:top-10 {
        top: 10rem;
    }

    .laptop\:bottom-10 {
        bottom: 10rem;
    }

    .laptop\:left-10 {
        left: 10rem;
    }

    .laptop\:right-10 {
        right: 10rem;
    }

    .laptop\:left-6-n {
        left: -6rem;
    }

    .laptop\:right-6-n {
        right: -6rem;
    }

    .laptop\:left-8-n {
        left: -8rem;
    }

    .laptop\:right-8-n {
        right: -8rem;
    }

    .laptop\:left-10-n {
        left: -10rem;
    }

    .laptop\:right-10-n {
        right: -10rem;
    }

    .laptop\:left-12-n {
        left: -12rem;
    }

    .laptop\:right-12-n {
        right: -12rem;
    }

    .laptop\:left-14-n {
        left: -14rem;
    }

    .laptop\:right-14-n {
        right: -14rem;
    }


    .laptop\:left-15-n {
        left: -15rem;
    }

    .laptop\:right-15-n {
        right: -15rem;
    }

    .laptop\:left-16-n {
        left: -16rem;
    }

    .laptop\:right-16-n {
        right: -16rem;
    }

    .laptop\:left-20-n {
        left: -20rem;
    }

    .laptop\:right-20-n {
        right: -20rem;
    }


    .laptop\:top-2-n {
        top: -2rem;
    }

    .laptop\:bottom-2-n {
        bottom: -2rem;
    }


    .laptop\:top-3-n {
        top: -3rem;
    }

    .laptop\:bottom-3-n {
        bottom: -3rem;
    }


    .laptop\:top-4-n {
        top: -4rem;
    }

    .laptop\:bottom-4-n {
        bottom: -4rem;
    }


    .laptop\:top-6-n {
        top: -6rem;
    }

    .laptop\:bottom-6-n {
        bottom: -6rem;
    }

    .laptop\:top-10-n {
        top: -10rem;
    }

    .laptop\:bottom-10-n {
        bottom: -10rem;
    }

    .laptop\:top-15 {
        top: 15rem;
    }

    .laptop\:top-16 {
        top: 16rem;
    }


    /*Translate*/
    .laptop\:tx-100px {
        transform: translateX(100px);
    }

    .laptop\:tx-200px {
        transform: translateX(200px);
    }

    .laptop\:tx-300px {
        transform: translateX(300px);
    }

    .laptop\:tx-100px-n {
        transform: translateX(-100px);
    }

    .laptop\:tx-200px-n {
        transform: translateX(-200px);
    }

    .laptop\:tx-300px-n {
        transform: translateX(-200px);
    }

    /*Transition*/
    .laptop\:t-2 {
        transition: all 0.2s;
    }

    .laptop\:t-3 {
        transition: all 0.3s;
    }

    .laptop\:t-4 {
        transition: all 0.4s;
    }

    .laptop\:t-5 {
        transition: all 0.5s;
    }


    /*Zindex*/
    .laptop\:z-10 {
        z-index: 10;
    }

    .laptop\:z-20 {
        z-index: 20;
    }

    .laptop\:z-30 {
        z-index: 30;
    }

    .laptop\:z-40 {
        z-index: 40;
    }

    .laptop\:z-50 {
        z-index: 50;
    }

    .laptop\:z-60 {
        z-index: 60;
    }

    .laptop\:z-70 {
        z-index: 70;
    }

    .laptop\:z-80 {
        z-index: 80;
    }

    .laptop\:z-90 {
        z-index: 90;
    }

    .laptop\:z-100 {
        z-index: 100;
    }

    .laptop\:z-200 {
        z-index: 200;
    }

    .laptop\:z-300 {
        z-index: 300;
    }

    .laptop\:z-400 {
        z-index: 400;
    }

    .laptop\:z-500 {
        z-index: 500;
    }

    .laptop\:z-600 {
        z-index: 600;
    }

    .laptop\:z-700 {
        z-index: 700;
    }

    .laptop\:z-800 {
        z-index: 800;
    }

    .laptop\:z-900 {
        z-index: 900;
    }

    .laptop\:z-1000 {
        z-index: 1000;
    }


    /*layout flex*/
    .laptop\:align-center {
        align-items: center;
    }

    .laptop\:align-end {
        align-items: flex-end;
    }

    .laptop\:align-start {
        align-items: flex-start;
    }

    .laptop\:justify-center {
        justify-content: center;
    }

    .laptop\:justify-end {
        justify-content: flex-end;
    }

    .laptop\:justify-start {
        justify-content: flex-start;
    }

    .laptop\:grows {
        flex-grow: 1;
    }

    .laptop\:grows-0 {
        flex-grow: 0;
    }

    .laptop\:shrinks {
        flex-shrink: 1;
    }

    .laptop\:shrinks-0 {
        flex-shrink: 0;
    }

    .laptop\:column {
        flex-basis: 0;
        flex-grow: 1;
        flex-shrink: 1;
    }

    .laptop\:direction-column {
        flex-direction: column;
    }

    .laptop\:direction-row-reverse {
        flex-direction: row-reverse;
    }

    .laptop\:direction-column-reverse {
        flex-direction: column-reverse;
    }

    .laptop\:no-wrap {
        flex-wrap: nowrap;
    }

    .laptop\:wrap {
        flex-wrap: wrap;
    }

    .laptop\:order-1 {
        order: 1;
    }

    .laptop\:order-2 {
        order: 2;
    }

    .laptop\:order-3 {
        order: 3;
    }

    .laptop\:order-4 {
        order: 4;
    }

    .laptop\:order-5 {
        order: 5;
    }

    .laptop\:order-6 {
        order: 6;
    }

    .laptop\:order-7 {
        order: 7;
    }

    .laptop\:order-8 {
        order: 8;
    }

    .laptop\:order-9 {
        order: 9;
    }

    .laptop\:col2 {
        width: 50%;
    }

    .laptop\:col3 {
        width: 33.33%;
    }

    .laptop\:col4 {
        width: 25%;
    }

    .laptop\:col5 {
        width: 20%;
    }


    /*columns*/
    .laptop\:grid1 {
        display: grid;
        grid-template-columns: repeat(1, minmax(0, 1fr));
    }

    .laptop\:grid2 {
        display: grid;
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }

    .laptop\:grid3 {
        display: grid;
        grid-template-columns: repeat(3, minmax(0, 1fr));
    }

    .laptop\:grid4 {
        display: grid;
        grid-template-columns: repeat(4, minmax(0, 1fr));
    }

    .laptop\:grid5 {
        display: grid;
        grid-template-columns: repeat(5, minmax(0, 1fr));
    }

    .laptop\:grid6 {
        display: grid;
        grid-template-columns: repeat(6, minmax(0, 1fr));
    }

    .laptop\:grid7 {
        display: grid;
        grid-template-columns: repeat(7, minmax(0, 1fr));
    }

    .laptop\:grid8 {
        display: grid;
        grid-template-columns: repeat(8, minmax(0, 1fr));
    }

    .laptop\:row-span2 {
        grid-row-start: span 2;
    }

    .laptop\:col-span2 {
        grid-column-start: span 2;
    }

    .laptop\:col-span3 {
        grid-column-start: span 3;
    }

    .laptop\:col-span4 {
        grid-column-start: span 4;
    }

    .laptop\:col-span5 {
        grid-column-start: span 5;
    }

    .laptop\:col-span6 {
        grid-column-start: span 5;
    }

    .laptop\:col-span7 {
        grid-column-start: span 5;
    }

    .laptop\:col-span8 {
        grid-column-start: span 5;
    }

    .laptop\:col-gap-quarter {
        grid-column-gap: 0.25rem;
    }

    .laptop\:col-gap-half {
        grid-column-gap: 0.5rem;
    }

    .laptop\:col-gap {
        grid-column-gap: 1rem;
    }

    .laptop\:col-gap-2 {
        grid-column-gap: 2rem;
    }

    .laptop\:col-gap-3 {
        grid-column-gap: 3rem;
    }

    .laptop\:col-gap-4 {
        grid-column-gap: 4rem;
    }

    .laptop\:col-gap-6 {
        grid-column-gap: 6rem;
    }

    .laptop\:col-gap-8 {
        grid-column-gap: 8rem;
    }

    .laptop\:row-gap-quarter {
        grid-row-gap: 0.25rem;
    }

    .laptop\:row-gap-half {
        grid-row-gap: 0.5rem;
    }

    .laptop\:row-gap {
        grid-row-gap: 1rem;
    }

    .laptop\:row-gap-2 {
        grid-row-gap: 2rem;
    }

    .laptop\:row-gap-3 {
        grid-row-gap: 3rem;
    }

    .laptop\:row-gap-4 {
        grid-row-gap: 4rem;
    }


    /*Sizes*/
    .laptop\:min-w-2400px {
        min-width: 2400px;
    }

    .laptop\:min-w-2000px {
        min-width: 2000px;
    }

    .laptop\:min-w-1800px {
        min-width: 1800px;
    }

    .laptop\:min-w-1600px {
        min-width: 1600px;
    }

    .laptop\:min-w-1400px {
        min-width: 1400px;
    }

    .laptop\:min-w-1360px {
        min-width: 1360px;
    }

    .laptop\:min-w-1200px {
        min-width: 1200px;
    }

    .laptop\:min-w-1056px {
        min-width: 1056px;
    }

    .laptop\:min-w-940px {
        min-width: 940px;
    }

    .laptop\:min-w-800px {
        min-width: 800px;
    }

    .laptop\:min-w-700px {
        min-width: 700px;
    }

    .laptop\:min-w-600px {
        min-width: 600px;
    }

    .laptop\:min-w-400px {
        min-width: 400px;
    }

    .laptop\:min-w-300px {
        min-width: 300px;
    }

    .laptop\:min-w-200px {
        min-width: 200px;
    }

    .laptop\:min-w-100px {
        min-width: 100px;
    }

    .laptop\:min-w-0 {
        min-width: 0px;
    }

    .laptop\:min-w-100 {
        min-width: 100%;
    }

    .laptop\:min-w-90 {
        min-width: 90%;
    }

    .laptop\:min-w-80 {
        min-width: 80%;
    }

    .laptop\:min-w-70 {
        min-width: 70%;
    }

    .laptop\:min-w-60 {
        min-width: 60%;
    }

    .laptop\:min-w-50 {
        min-width: 50%;
    }


    .laptop\:max-w-2400px {
        max-width: 2400px;
    }

    .laptop\:max-w-2000px {
        max-width: 2000px;
    }

    .laptop\:max-w-1800px {
        max-width: 1800px;
    }

    .laptop\:max-w-1600px {
        max-width: 1600px;
    }

    .laptop\:max-w-1400px {
        max-width: 1400px;
    }

    .laptop\:max-w-1360px {
        max-width: 1360px;
    }

    .laptop\:max-w-1200px {
        max-width: 1200px;
    }

    .laptop\:max-w-1056px {
        max-width: 1056px;
    }

    .laptop\:max-w-940px {
        max-width: 940px;
    }

    .laptop\:max-w-800px {
        max-width: 800px;
    }

    .laptop\:max-w-700px {
        max-width: 700px;
    }

    .laptop\:max-w-600px {
        max-width: 600px;
    }

    .laptop\:max-w-500px {
        max-width: 500px;
    }

    .laptop\:max-w-400px {
        max-width: 400px;
    }

    .laptop\:max-w-350px {
        max-width: 350px;
    }

    .laptop\:max-w-300px {
        max-width: 300px;
    }

    .laptop\:max-w-200px {
        max-width: 200px;
    }

    .laptop\:max-w-100px {
        max-width: 100px;
    }

    .laptop\:max-w-100 {
        max-width: 100%;
    }

    .laptop\:max-w-90 {
        max-width: 90%;
    }

    .laptop\:max-w-80 {
        max-width: 80%;
    }

    .laptop\:max-w-70 {
        max-width: 70%;
    }

    .laptop\:max-w-60 {
        max-width: 60%;
    }

    .laptop\:max-w-50 {
        max-width: 50%;
    }

    /*Width*/
    .laptop\:w-0 {
        width: 0;
    }

    .laptop\:w-1rem {
        width: 1rem;
    }

    .laptop\:w-2rem {
        width: 2rem;
    }

    .laptop\:w-3rem {
        width: 3rem;
    }

    .laptop\:w-4rem {
        width: 4rem;
    }

    .laptop\:w-5rem {
        width: 5rem;
    }

    .laptop\:w-6rem {
        width: 6rem;
    }

    .laptop\:w-7rem {
        width: 7rem;
    }

    .laptop\:w-8rem {
        width: 8rem;
    }

    .laptop\:w-10rem {
        width: 10rem;
    }

    .laptop\:w-15rem {
        width: 15rem;
    }

    .laptop\:w-20rem {
        width: 20rem;
    }

    .laptop\:w-100 {
        width: 100%;
    }

    .laptop\:w-90 {
        width: 90%;
    }

    .laptop\:w-80 {
        width: 80%;
    }

    .laptop\:w-70 {
        width: 70%;
    }


    .laptop\:w-60 {
        width: 60%;
    }


    .laptop\:w-50 {
        width: 50%;
    }

    .laptop\:w-40 {
        width: 40%;
    }

    .laptop\:w-33 {
        width: 33.33%;
    }

    .laptop\:w-30 {
        width: 30%;
    }

    .laptop\:w-25 {
        width: 25%;
    }

    .laptop\:w-20 {
        width: 20%;
    }


    .laptop\:w-10 {
        width: 10%;
    }

    .laptop\:w-auto {
        width: auto;
    }


    .laptop\:w-10px {
        width: 10px;
    }

    .laptop\:w-20px {
        width: 20px;
    }

    .laptop\:w-30px {
        width: 30px;
    }

    .laptop\:w-40px {
        width: 40px;
    }

    .laptop\:w-50px {
        width: 50px;
    }

    .laptop\:w-60px {
        width: 60px;
    }

    .laptop\:w-70px {
        width: 70px;
    }

    .laptop\:w-80px {
        width: 80px;
    }

    .laptop\:w-90px {
        width: 90px;
    }

    .laptop\:w-100px {
        width: 100px;
    }

    .laptop\:w-150px {
        width: 150px;
    }

    .laptop\:w-200px {
        width: 200px;
    }

    .laptop\:w-250px {
        width: 250px;
    }

    .laptop\:w-300px {
        width: 300px;
    }

    .laptop\:w-350px {
        width: 350px;
    }

    .laptop\:w-400px {
        width: 400px;
    }

    .laptop\:w-500px {
        width: 500px;
    }

    .laptop\:w-600px {
        width: 600px;
    }

    .laptop\:w-800px {
        width: 800px;
    }

    .laptop\:w-1000px {
        width: 1000px;
    }


    /*height*/
    .laptop\:h-auto {
        height: auto;
    }

    .laptop\:h-halfrem {
        height: 0.5rem;
    }

    .laptop\:h-1rem {
        height: 1rem;
    }

    .laptop\:h-1halfrem {
        height: 1.5rem;
    }

    .laptop\:h-2rem {
        height: 2rem;
    }

    .laptop\:h-2halfrem {
        height: 2.5rem;
    }

    .laptop\:h-3rem {
        height: 3rem;
    }

    .laptop\:h-3halfrem {
        height: 3.5rem;
    }

    .laptop\:h-4rem {
        height: 4rem;
    }

    .laptop\:h-4halfrem {
        height: 4.5rem;
    }

    .laptop\:h-5rem {
        height: 5rem;
    }

    .laptop\:h-6rem {
        height: 6rem;
    }

    .laptop\:h-7rem {
        height: 7rem;
    }

    .laptop\:h-8rem {
        height: 8rem;
    }

    .laptop\:h-10rem {
        height: 10rem;
    }

    .laptop\:h-15rem {
        height: 15rem;
    }

    .laptop\:h-20rem {
        height: 20rem;
    }

    .laptop\:h-10vh {
        height: 10vh;
    }

    .laptop\:h-20vh {
        height: 20vh;
    }

    .laptop\:h-30vh {
        height: 30vh;
    }

    .laptop\:h-40vh {
        height: 40vh;
    }

    .laptop\:h-50vh {
        height: 50vh;
    }

    .laptop\:h-60vh {
        height: 60vh;
    }

    .laptop\:h-70vh {
        height: 70vh;
    }

    .laptop\:h-80vh {
        height: 80vh;
    }

    .laptop\:h-90vh {
        height: 90vh;
    }

    .laptop\:h-100vh {
        height: 100vh;
    }

    .laptop\:h-10 {
        height: 10%;
    }

    .laptop\:h-20 {
        height: 20%;
    }

    .laptop\:h-30 {
        height: 30%;
    }

    .laptop\:h-40 {
        height: 40%;
    }

    .laptop\:h-50 {
        height: 50%;
    }

    .laptop\:h-60 {
        height: 60%;
    }

    .laptop\:h-100 {
        height: 100%;
    }

    .laptop\:h-110 {
        height: 110%;
    }

    .laptop\:h-120 {
        height: 120%;
    }

    .laptop\:h-130 {
        height: 130%;
    }

    .laptop\:h-140 {
        height: 140%;
    }

    .laptop\:h-150 {
        height: 150%;
    }

    .laptop\:h-100px {
        height: 100px;
    }

    .laptop\:h-150px {
        height: 150px;
    }

    .laptop\:h-200px {
        height: 200px;
    }

    .laptop\:h-250px {
        height: 250px;
    }

    .laptop\:h-300px {
        height: 300px;
    }

    .laptop\:h-400px {
        height: 400px;
    }

    .laptop\:h-500px {
        height: 500px;
    }

    .laptop\:h-600px {
        height: 600px;
    }

    .laptop\:h-800px {
        height: 800px;
    }

    .laptop\:h-1000px {
        height: 1000px;
    }


    .laptop\:min-h-0 {
        min-height: 0;
    }

    .laptop\:min-h-100vh {
        min-height: 100vh;
    }

    .laptop\:min-h-90vh {
        min-height: 90vh;
    }

    .laptop\:min-h-80vh {
        min-height: 80vh;
    }

    .laptop\:min-h-70vh {
        min-height: 70vh;
    }

    .laptop\:min-h-60vh {
        min-height: 60vh;
    }

    .laptop\:min-h-50vh {
        min-height: 50vh;
    }

    .laptop\:min-h-40vh {
        min-height: 40vh;
    }

    .laptop\:min-h-30vh {
        min-height: 30vh;
    }

    .laptop\:min-h-20vh {
        min-height: 20vh;
    }

    .laptop\:min-h-10vh {
        min-height: 10vh;
    }


    .laptop\:min-h-100px {
        min-height: 100px;
    }

    .laptop\:min-h-200px {
        min-height: 200px;
    }

    .laptop\:min-h-300px {
        min-height: 300px;
    }

    .laptop\:min-h-400px {
        min-height: 400px;
    }

    .laptop\:min-h-500px {
        min-height: 500px;
    }

    .laptop\:min-h-600px {
        min-height: 600px;
    }

    .laptop\:min-h-700px {
        min-height: 700px;
    }

    .laptop\:min-h-800px {
        min-height: 800px;
    }


    .laptop\:max-h-0 {
        max-height: 0;
    }

    .laptop\:max-h-100vh {
        max-height: 100vh;
    }

    .laptop\:max-h-90vh {
        max-height: 90vh;
    }

    .laptop\:max-h-80vh {
        max-height: 80vh;
    }

    .laptop\:max-h-70vh {
        max-height: 70vh;
    }

    .laptop\:max-h-60vh {
        max-height: 60vh;
    }

    .laptop\:max-h-50vh {
        max-height: 50vh;
    }

    .laptop\:max-h-40vh {
        max-height: 40vh;
    }

    .laptop\:max-h-30vh {
        max-height: 30vh;
    }

    .laptop\:max-h-20vh {
        max-height: 20vh;
    }

    .laptop\:max-h-10vh {
        max-height: 10vh;
    }


    .laptop\:max-h-100px {
        max-height: 100px;
    }

    .laptop\:max-h-200px {
        max-height: 200px;
    }

    .laptop\:max-h-300px {
        min-height: 300px;
    }

    .laptop\:max-h-400px {
        min-height: 400px;
    }

    .laptop\:max-h-500px {
        max-height: 500px;
    }

    .laptop\:max-h-600px {
        max-height: 600px;
    }

    .laptop\:max-h-700px {
        max-height: 700px;
    }

    .laptop\:max-h-800px {
        max-height: 800px;
    }


    .laptop\:sq-1rem {
        width: 1rem;
        height: 1rem;
    }

    .laptop\:sq-1halfrem {
        width: 1.5rem;
        height: 1.5rem;
    }

    .laptop\:sq-2rem {
        width: 2rem;
        height: 2rem;
    }

    .laptop\:sq-2halfrem {
        width: 2.5rem;
        height: 2.5rem;
    }

    .laptop\:sq-3rem {
        width: 3rem;
        height: 3rem;
    }

    .laptop\:sq-3halfrem {
        width: 3.5rem;
        height: 3.5rem;
    }


    .laptop\:sq-4rem {
        width: 4rem;
        height: 4rem;
    }

    .laptop\:sq-4halfrem {
        width: 4.5rem;
        height: 4.5rem;
    }


    .laptop\:sq-5rem {
        width: 5rem;
        height: 5rem;
    }

    .laptop\:sq-6rem {
        width: 6rem;
        height: 6rem;
    }

    .laptop\:sq-7rem {
        width: 7rem;
        height: 7rem;
    }

    .laptop\:sq-8rem {
        width: 8rem;
        height: 8rem;
    }

    .laptop\:sq-10rem {
        width: 10rem;
        height: 10rem;
    }

    .laptop\:sq-15rem {
        width: 15rem;
        height: 15rem;
    }

    .laptop\:sq-20rem {
        width: 20rem;
        height: 20rem;
    }

    /*Overflows*/
    .laptop\:overflow-y-scroll {
        overflow-y: scroll;
    }

    .laptop\:overflow-y-auto {
        overflow-y: auto;
    }

    .laptop\:overflow-x-scroll {
        overflow-x: scroll;
    }

    .laptop\:overflow-x-hidden {
        overflow-x: hidden;
    }

    .laptop\:overflow-y-auto {
        overflow-x: auto;
    }

    .laptop\:overflow-hidden {
        overflow: hidden;
    }

    /*Margins*/
    .laptop\:m-0 {
        margin: 0;
    }

    .laptop\:m-auto {
        margin: auto;
    }

    .laptop\:mver-auto {
        margin-top: auto;
        margin-bottom: auto;
    }

    .laptop\:mhor-auto {
        margin-right: auto;
        margin-left: auto;
    }

    .laptop\:m-0 {
        margin: 0;
    }

    .laptop\:ml-0 {
        margin-left: 0;
    }

    .laptop\:mr-0 {
        margin-right: 0;
    }

    .laptop\:mt-0 {
        margin-top: 0;
    }

    .laptop\:mb-0 {
        margin-bottom: 0;
    }

    .laptop\:m {
        margin: 1rem;
    }

    .laptop\:ml {
        margin-left: 1rem;
    }

    .laptop\:mr {
        margin-right: 1rem;
    }

    .laptop\:mb, .laptop\:childs-mb > * {
        margin-bottom: 1rem;
    }

    .laptop\:mt {
        margin-top: 1rem;
    }

    .laptop\:mv {
        margin-bottom: 1rem;
        margin-top: 1rem;
    }

    .laptop\:mhor {
        margin-right: 1rem;
        margin-left: 1rem;
    }

    .laptop\:mver {
        margin-top: 1rem;
        margin-bottom: 1rem;
    }

    .laptop\:m-half {
        margin: 0.5rem;
    }

    .laptop\:ml-half {
        margin-left: 0.5rem;
    }

    .laptop\:mr-half {
        margin-right: 0.5rem;
    }

    .laptop\:mb-half, .laptop\:childs-mb-half > * {
        margin-bottom: 0.5rem;
    }

    .laptop\:mt-half {
        margin-top: 0.5rem;
    }

    .laptop\:mv-half {
        margin-bottom: 0.5rem;
        margin-top: 0.5rem;
    }

    .laptop\:mhor-half {
        margin-right: 0.5rem;
        margin-left: 0.5rem;
    }


    .laptop\:m-quarter {
        margin: 0.25rem;
    }

    .laptop\:ml-quarter {
        margin-left: 0.25rem;
    }

    .laptop\:mr-quarter {
        margin-right: 0.25rem;
    }

    .laptop\:mb-quarter {
        margin-bottom: 0.25rem;
    }

    .laptop\:mt-quarter {
        margin-top: 0.25rem;
    }

    .laptop\:mver-quarter {
        margin-bottom: 0.25rem;
        margin-top: 0.25rem;
    }

    .laptop\:mhor-quarter {
        margin-right: 0.25rem;
        margin-left: 0.25rem;
    }


    .laptop\:m-2 {
        margin: 2rem;
    }

    .laptop\:ml-2 {
        margin-left: 2rem;
    }

    .laptop\:mr-2 {
        margin-right: 2rem;
    }

    .laptop\:mb-2 {
        margin-bottom: 2rem;
    }

    .laptop\:mt-2 {
        margin-top: 2rem;
    }

    .laptop\:mver-2 {
        margin-bottom: 2rem;
        margin-top: 2rem;
    }

    .laptop\:mhor-2 {
        margin-right: 2rem;
        margin-left: 2rem;
    }

    .laptop\:m-3 {
        margin: 3rem;
    }

    .laptop\:ml-3 {
        margin-left: 3rem;
    }

    .laptop\:mr-3 {
        margin-right: 3rem;
    }

    .laptop\:mb-3 {
        margin-bottom: 3rem;
    }

    .laptop\:mb-3-n {
        margin-bottom: -3rem;
    }

    .laptop\:mt-3 {
        margin-top: 3rem;
    }

    .laptop\:mver-3 {
        margin-bottom: 3rem;
        margin-top: 3rem;
    }

    .laptop\:mhor-3 {
        margin-right: 3rem;
        margin-left: 3rem;
    }

    .laptop\:m-4 {
        margin: 4rem;
    }

    .laptop\:ml-4 {
        margin-left: 4rem;
    }

    .laptop\:ml-6 {
        margin-left: 6rem;
    }

    .laptop\:ml-8 {
        margin-left: 8rem;
    }

    .laptop\:ml-10 {
        margin-left: 10rem;
    }

    .laptop\:mr-4 {
        margin-right: 4rem;
    }

    .laptop\:mr-6 {
        margin-right: 6rem;
    }

    .laptop\:mr-8 {
        margin-right: 8rem;
    }

    .laptop\:mb-4 {
        margin-bottom: 4rem;
    }


    .laptop\:mb-6 {
        margin-bottom: 6rem;
    }

    .laptop\:mb-8 {
        margin-bottom: 8rem;
    }

    .laptop\:mb-10 {
        margin-bottom: 10rem;
    }

    .laptop\:mt-4 {
        margin-top: 4rem;
    }


    .laptop\:mt-6 {
        margin-top: 6rem;
    }


    .laptop\:mt-8 {
        margin-top: 8rem;
    }


    .laptop\:mt-10 {
        margin-top: 10rem;
    }

    .laptop\:mver-4 {
        margin-bottom: 4rem;
        margin-top: 4rem;
    }

    .laptop\:mhor-4 {
        margin-right: 4rem;
        margin-left: 4rem;
    }

    /*Padding*/
    .laptop\:p-0 {
        padding: 0;
    }

    .laptop\:pl-0 {
        padding-left: 0;
    }

    .laptop\:pr-0 {
        padding-right: 0;
    }

    .laptop\:pb-0 {
        padding-bottom: 0;
    }

    .laptop\:pt-0 {
        padding-top: 0;
    }


    .laptop\:p {
        padding: 1rem;
    }

    .laptop\:pl {
        padding-left: 1rem;
    }

    .laptop\:pr {
        padding-right: 1rem;
    }

    .laptop\:pb {
        padding-bottom: 1rem;
    }

    .laptop\:pt {
        padding-top: 1rem;
    }

    .laptop\:pver-0 {
        padding-bottom: 0rem;
        padding-top: 0rem;
    }

    .laptop\:pver, .laptop\:childs-pver > * {
        padding-bottom: 1rem;
        padding-top: 1rem;
    }

    .laptop\:phor-0 {
        padding-right: 0rem;
        padding-left: 0rem;
    }

    .laptop\:phor {
        padding-right: 1rem;
        padding-left: 1rem;
    }

    .laptop\:p-half {
        padding: 0.5rem;
    }

    .laptop\:pl-half {
        padding-left: 0.5rem;
    }

    .laptop\:pr-half {
        padding-right: 0.5rem;
    }

    .laptop\:pb-half {
        padding-bottom: 0.5rem;
    }

    .laptop\:pt-half {
        padding-top: 0.5rem;
    }

    .laptop\:pver-half, .laptop\:childs-pver-half > * {
        padding-bottom: 0.5rem;
        padding-top: 0.5rem;
    }

    .laptop\:phor-half {
        padding-right: 0.5rem;
        padding-left: 0.5rem;
    }


    .laptop\:p-quarter {
        padding: 0.25rem;
    }

    .laptop\:pl-quarter {
        padding-left: 0.25rem;
    }

    .laptop\:pr-quarter {
        padding-right: 0.25rem;
    }

    .laptop\:pb-quarter {
        padding-bottom: 0.25rem;
    }

    .laptop\:pt-quarter {
        padding-top: 0.25rem;
    }

    .laptop\:pver-quarter, .laptop\:childs-pver-quarter > * {
        padding-bottom: 0.25rem;
        padding-top: 0.25rem;
    }

    .laptop\:phor-quarter {
        padding-right: 0.25rem;
        padding-left: 0.25rem;
    }


    .laptop\:p-2 {
        padding: 2rem;
    }

    .laptop\:pl-2 {
        padding-left: 2rem;
    }

    .laptop\:pr-2 {
        padding-right: 2rem;
    }

    .laptop\:pb-2 {
        padding-bottom: 2rem;
    }

    .laptop\:pt-2 {
        padding-top: 2rem;
    }

    .laptop\:pver-2 {
        padding-bottom: 2rem;
        padding-top: 2rem;
    }

    .laptop\:phor-2 {
        padding-right: 2rem;
        padding-left: 2rem;
    }

    .laptop\:p-3 {
        padding: 3rem;
    }

    .laptop\:pl-3 {
        padding-left: 3rem;
    }

    .laptop\:pr-3 {
        padding-right: 3rem;
    }

    .laptop\:pb-3 {
        padding-bottom: 3rem;
    }

    .laptop\:pt-3 {
        padding-top: 3rem;
    }

    .laptop\:pver-3 {
        padding-bottom: 3rem;
        padding-top: 3rem;
    }

    .laptop\:phor-3 {
        padding-right: 3rem;
        padding-left: 3rem;
    }

    .laptop\:p-4 {
        padding: 4rem;
    }

    .laptop\:pl-4 {
        padding-left: 4rem;
    }

    .laptop\:pr-4 {
        padding-right: 4rem;
    }

    .laptop\:pb-4 {
        padding-bottom: 4rem;
    }

    .laptop\:pt-4 {
        padding-top: 4rem;
    }

    .laptop\:pver-4 {
        padding-bottom: 4rem;
        padding-top: 4rem;
    }

    .laptop\:phor-4 {
        padding-right: 4rem;
        padding-left: 4rem;
    }

    .laptop\:p-6 {
        padding: 6rem;
    }

    .laptop\:pl-6 {
        padding-left: 6rem;
    }

    .laptop\:pr-6 {
        padding-right: 6rem;
    }

    .laptop\:pb-6 {
        padding-bottom: 6rem;
    }

    .laptop\:pt-6 {
        padding-top: 6rem;
    }

    .laptop\:pver-6 {
        padding-bottom: 6rem;
        padding-top: 6rem;
    }

    .laptop\:phor-6 {
        padding-right: 6rem;
        padding-left: 6rem;
    }

    .laptop\:p-8 {
        padding: 8rem;
    }

    .laptop\:pl-8 {
        padding-left: 8rem;
    }

    .laptop\:pr-8 {
        padding-right: 8rem;
    }

    .laptop\:pb-8 {
        padding-bottom: 8rem;
    }

    .laptop\:pt-8 {
        padding-top: 8rem;
    }

    .laptop\:pver-8 {
        padding-bottom: 8rem;
        padding-top: 8rem;
    }

    .laptop\:phor-8 {
        padding-right: 8rem;
        padding-left: 8rem;
    }

    .laptop\:pb-100 {
        padding-bottom: 100%;
    }


    /*objects*/
    .laptop\:obj-cover {
        object-fit: cover;
    }

    .laptop\:obj-contain {
        object-fit: contain;
    }

    .laptop\:obj-top {
        object-position: top;
    }

    .laptop\:obj-bottom {
        object-position: bottom;
    }

    .laptop\:obj-left {
        object-position: left;
    }

    .laptop\:obj-top-left {
        object-position: left top;
    }

    .laptop\:obj-right {
        object-position: right;
    }

    .laptop\:bg-contain {
        background-size: contain;
    }

    .laptop\:bg-no-repeat {
        background-repeat: no-repeat;
    }

    .laptop\:bg-repeat {
        background-repeat: repeat;
    }

    .laptop\:bg-cover {
        background-size: cover;
    }

    .laptop\:bg-top {
        background-position: top;
    }

    .laptop\:bg-bottom {
        background-position: bottom;
    }

    .laptop\:bg-left {
        background-position: left;
    }

    .laptop\:bg-right {
        background-position: right;
    }

    .laptop\:bg-center {
        background-position: center;
    }


    /*Corners*/
    .laptop\:br-0 {
        border-radius: 0rem;
    }

    .laptop\:br-quarter {
        border-radius: 0.25rem;
    }

    .laptop\:br-half {
        border-radius: 0.5rem;
    }

    .laptop\:br-1half {
        border-radius: 1.5rem;
    }

    .laptop\:br {
        border-radius: 1rem;
    }

    .laptop\:br-2 {
        border-radius: 2rem;
    }

    .laptop\:br-2half {
        border-radius: 2.5rem;
    }

    .laptop\:br-3 {
        border-radius: 3rem;
    }

    .laptop\:br-4 {
        border-radius: 4rem;
    }

    .laptop\:br-8 {
        border-radius: 8rem;
    }

    .laptop\:br-50 {
        border-radius: 50%;
    }

    /*borders*/
    .laptop\:b-0 {
        border: 0px;
    }

    .laptop\:b-top-0 {
        border-top: 0px solid;
    }

    .laptop\:b-right-0 {
        border-right: 0px solid;
    }

    .laptop\:b-bottom-0 {
        border-bottom: 0px solid;
    }

    .laptop\:b-left-0 {
        border-left: 0px solid;
    }

    .laptop\:b {
        border: 1px solid;
    }

    .laptop\:b-right {
        border-right: 1px solid;
    }

    .laptop\:b-bottom {
        border-bottom: 1px solid;
    }

    .laptop\:b-left {
        border-left: 1px solid;
    }

    .laptop\:b-top {
        border-top: 1px solid;
    }

    .laptop\:b-2 {
        border: 2px solid;
    }

    .laptop\:b-right-2 {
        border-right: 2px solid;
    }

    .laptop\:b-bottom-2 {
        border-bottom: 2px solid;
    }

    .laptop\:b-left-2 {
        border-left: 2px solid;
    }

    .laptop\:b-top-2 {
        border-top: 2px solid;
    }


    .laptop\:b-3 {
        border: 3px solid;
    }

    .laptop\:b-right-3 {
        border-right: 3px solid;
    }

    .laptop\:b-bottom-3 {
        border-bottom: 3px solid;
    }

    .laptop\:b-left-3 {
        border-left: 3px solid;
    }

    .laptop\:b-top-3 {
        border-top: 3px solid;
    }

    .laptop\:b-4 {
        border: 4px solid;
    }

    .laptop\:b-right-4 {
        border-right: 4px solid;
    }

    .laptop\:b-bottom-4 {
        border-bottom: 4px solid;
    }

    .laptop\:b-left-4 {
        border-left: 4px solid;
    }

    .laptop\:b-top-4 {
        border-top: 4px solid;
    }

    .laptop\:b-5 {
        border: 5px solid;
    }

    .laptop\:b-right-5 {
        border-right: 5px solid;
    }

    .laptop\:b-bottom-5 {
        border-bottom: 5px solid;
    }

    .laptop\:b-left-5 {
        border-left: 5px solid;
    }

    .laptop\:b-top-5 {
        border-top: 5px solid;
    }

    .laptop\:focus\:outline-0 {
        outline: 0;
    }

    /*Opacity*/
    .laptop\:op-0, .laptop\:hover\:op-0:hover {
        opacity: 0;
    }

    .laptop\:op-10, .laptop\:hover\:op-10:hover {
        opacity: .1;
    }

    .laptop\:op-20, .laptop\:hover\:op-20:hover {
        opacity: .20;
    }

    .laptop\:op-30, .laptop\:hover\:op-30:hover {
        opacity: .30;
    }

    .laptop\:op-40, .laptop\:hover\:op-40:hover {
        opacity: .40;
    }

    .laptop\:op-50, .laptop\:hover\:op-50:hover {
        opacity: .5;
    }

    .laptop\:op-60, .laptop\:hover\:op-60:hover {
        opacity: .60;
    }

    .laptop\:op-70, .laptop\:hover\:op-70:hover {
        opacity: .70;
    }

    .laptop\:op-80, .laptop\:hover\:op-80:hover {
        opacity: .80;
    }

    .laptop\:op-90, .laptop\:hover\:op-90:hover {
        opacity: .90;
    }

    .laptop\:op-100, .laptop\:hover\:op-100:hover {
        opacity: 1;
    }


    .laptop\:transition-3 {
        transition: all 0.3s;
    }

    .laptop\:transition-4 {
        transition: all 0.4s;
    }

    .laptop\:transition-5 {
        transition: all 0.5s;
    }

    .laptop\:transition-6 {
        transition: all 0.6s;
    }

    .laptop\:cursor-pointer {
        cursor: pointer;
    }

    .laptop\:list-none {
        list-style: none;
    }

    .laptop\:no-wrap {
        white-space: nowrap;
    }

    .laptop\:break-word {
        overflow-wrap: break-word;
        word-wrap: break-word;
        -webkit-hyphens: auto;
            -ms-hyphens: auto;
                hyphens: auto;
    }


    .laptop\:scale-1-1, .laptop\:hover\:scale-1-1:hover {
        transform: scale(1.1);
    }

    .laptop\:scale-1-2, .laptop\:hover\:scale-1-2:hover {
        transform: scale(1.2);
    }


    .laptop\:translate-y, .laptop\:hover\:translate-y:hover {
        transform: translateY(1rem);
    }

    .laptop\:translate-y-half, .laptop\:hover\:translate-y-half:hover {
        transform: translateY(0.5rem);
    }


    .laptop\:translate-y-n, .laptop\:hover\:translate-y-n:hover {
        transform: translateY(-1rem);
    }

    .laptop\:translate-y-half-n, .laptop\:hover\:translate-y-half-n:hover {
        transform: translateY(-0.5rem);
    }

}

@media (max-width: 640px) {


    .mobile\:va-top {
        vertical-align: top;
    }

    .mobile\:va-bottom {
        vertical-align: bottom;
    }

    .mobile\:va-center {
        vertical-align: center;
    }


    .mobile\:checkbox {
        display: inline-block;
        height: 1.5rem;
        width: 1.5rem;
        overflow: hidden;
        vertical-align: middle;
        -webkit-appearance: none;
           -moz-appearance: none;
                appearance: none;
        background-color: white;
        background-repeat: no-repeat;
        background-position: 50% 50%;
        border: 1px solid #1D3745;
    }

    .mobile\:checkbox:checked {
        background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTMiIHZpZXdCb3g9IjAgMCAxOCAxMyIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTE3IDFMNiAxMkwxIDciIHN0cm9rZT0iIzFEMzc0NSIgc3Ryb2tlLXdpZHRoPSIyIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4KPC9zdmc+Cg==");
        background-size: 80%;
    }

    .mobile\:radio {
        display: inline-block;
        height: 1.5rem;
        width: 1.5rem;
        overflow: hidden;
        vertical-align: middle;
        -webkit-appearance: none;
           -moz-appearance: none;
                appearance: none;
        background-color: white;
        background-repeat: no-repeat;
        background-position: 50% 50%;
        border: 1px solid #1D3745;
        border-radius: 50%;
    }

    .mobile\:radio:checked {
        background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIHZpZXdCb3g9IjAgMCAxNiAxNiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHJlY3QgeD0iMC41IiB5PSIwLjUiIHdpZHRoPSIxNSIgaGVpZ2h0PSIxNSIgcng9IjcuNSIgZmlsbD0iIzFEMzc0NSIgc3Ryb2tlPSIjMUQzNzQ1Ii8+Cjwvc3ZnPgo=");
        background-size: 70%;
    }

    /*Squares*/
    .mobile\:icon {
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
        display: inline-flex;
    }

    .mobile\:f-100 {
        font-weight: 100;
    }

    .mobile\:f-200 {
        font-weight: 200;
    }

    .mobile\:f-300 {
        font-weight: 300;
    }

    .mobile\:f-400 {
        font-weight: 400;
    }

    .mobile\:f-500 {
        font-weight: 500;
    }

    .mobile\:f-600 {
        font-weight: 600;
    }

    .mobile\:f-700 {
        font-weight: 700;
    }

    .mobile\:f-800 {
        font-weight: 800;
    }

    .mobile\:f-900 {
        font-weight: 900;
    }

    .mobile\:f-bold {
        font-weight: bold;
    }

    .mobile\:f-bolder {
        font-weight: bolder;
    }

    .mobile\:f-normal {
        font-weight: normal;
    }

    .mobile\:f-lighter {
        font-weight: lighter;
    }

    .mobile\:f-light {
        font-weight: light;
    }

    .mobile\:f-italic {
        font-style: italic;
    }

    .mobile\:f-underline {
        text-decoration: underline;
    }

    .mobile\:f-line-through {
        text-decoration: line-through;
    }

    .mobile\:f-uppercase {
        text-transform: uppercase;
    }

    .mobile\:f-1rem {
        font-size: 1rem;
    }

    .mobile\:f-medium {
        font-size: 1.2rem;
    }

    .mobile\:f-large {
        font-size: 1.5rem;
    }

    .mobile\:f-small {
        font-size: 0.8rem;
    }

    .mobile\:lh-80 {
        line-height: 80%;
    }

    .mobile\:lh-90 {
        line-height: 90%;
    }


    .mobile\:lh-100 {
        line-height: 100%;
    }

    .mobile\:lh-120 {
        line-height: 120%;
    }

    .mobile\:lh-150 {
        line-height: 150%;
    }

    .mobile\:lh-200 {
        line-height: 200%;
    }

    .mobile\:letter-1 {
        letter-spacing: 1px;
    }

    .mobile\:letter-2 {
        letter-spacing: 2px;
    }

    .mobile\:letter-3 {
        letter-spacing: 3px;
    }

    .mobile\:letter-4 {
        letter-spacing: 4px;
    }

    .mobile\:letter-5 {
        letter-spacing: 5px;
    }

    .mobile\:letter-6 {
        letter-spacing: 6px;
    }

    .mobile\:letter-1-n {
        letter-spacing: -1px;
    }

    .mobile\:letter-2-n {
        letter-spacing: -2px;
    }

    .mobile\:letter-3-n {
        letter-spacing: -3px;
    }

    .mobile\:letter-4-n {
        letter-spacing: -4px;
    }

    .mobile\:letter-5-n {
        letter-spacing: -5px;
    }

    .mobile\:letter-6-n {
        letter-spacing: -6px;
    }


    /*Titles*/
    .mobile\:h0 {
        font-size: 6rem;
    }

    .mobile\:h1, h1 {
        font-size: 4rem;
    }

    .mobile\:h2, h2 {
        font-size: 2.8rem;
    }

    .mobile\:h3, h3 {
        font-size: 2.5rem;
    }

    .mobile\:h4, h4 {
        font-size: 2rem;
    }

    .mobile\:h5, h5 {
        font-size: 1.6rem;
    }

    .mobile\:h6, h6 {
        font-size: 1.2rem;
    }

    /*Paragraph* */
    .mobile\:left {
        text-align: left;
    }

    .mobile\:right {
        text-align: right;
    }

    .mobile\:center {
        text-align: center;
    }

    /*textbox*/
    .mobile\:textbox a:not(.button) {
        text-decoration: underline;
    }

    .mobile\:textbox ul, .mobile\:textbox ol {
        padding-left: 2rem;
        margin-bottom: 1rem;
    }


    /*display*/
    .mobile\:flex {
        display: flex;
    }

    .mobile\:block, .mobile\:childs-block > * {
        display: block;
    }

    .mobile\:inline-flex {
        display: inline-flex;
    }

    .mobile\:grid {
        display: grid;
    }

    .mobile\:hidden {
        display: none;
    }

    /*position*/
    .mobile\:relative {
        position: relative;
    }

    .mobile\:absolute {
        position: absolute;
    }

    .mobile\:static {
        position: static;
    }

    .mobile\:fixed {
        position: fixed;
    }

    .mobile\:sticky {
        position: -webkit-sticky;
        position: sticky;
    }

    .mobile\:edges {
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
    }

    .mobile\:left-auto {
        left: auto;
    }

    .mobile\:right-auto {
        right: auto;
    }

    .mobile\:top-auto {
        top: auto;
    }

    .mobile\:bottom-auto {
        bottom: auto;
    }

    .mobile\:top-1-n {
        top: -1rem;
    }

    .mobile\:bottom-1-n {
        bottom: -1rem;
    }

    .mobile\:left-1-n {
        left: -1rem;
    }

    .mobile\:right-1-n {
        right: -1rem;
    }

    .mobile\:top-0 {
        top: 0;
    }

    .mobile\:bottom-0 {
        bottom: 0;
    }

    .mobile\:left-0 {
        left: 0;
    }

    .mobile\:right-0 {
        right: 0;
    }

    .mobile\:top-quarter {
        top: 0.25rem
    }

    .mobile\:bottom-quarter {
        bottom: 0.25rem
    }

    .mobile\:left-quarter {
        left: 0.25rem
    }

    .mobile\:right-quarter {
        right: 0.25rem
    }


    .mobile\:top-half {
        top: 0.5rem
    }

    .mobile\:bottom-half {
        bottom: 0.5rem
    }

    .mobile\:left-half {
        left: 0.5rem
    }

    .mobile\:right-half {
        right: 0.5rem
    }


    .mobile\:top-1 {
        top: 1rem;
    }

    .mobile\:bottom-1 {
        bottom: 1rem;
    }

    .mobile\:left-1 {
        left: 1rem;
    }

    .mobile\:right-1 {
        right: 1rem;
    }

    .mobile\:top-2 {
        top: 2rem;
    }

    .mobile\:bottom-2 {
        bottom: 2rem;
    }

    .mobile\:left-2 {
        left: 2rem;
    }

    .mobile\:right-2 {
        right: 2rem;
    }

    .mobile\:top-3 {
        top: 3rem;
    }

    .mobile\:bottom-3 {
        bottom: 3rem;
    }

    .mobile\:left-3 {
        left: 3rem;
    }

    .mobile\:right-3 {
        right: 3rem;
    }

    .mobile\:top-4 {
        top: 4rem;
    }

    .mobile\:top-4half {
        top: 4.5rem;
    }

    .mobile\:top-4-n {
        top: -4rem;
    }

    .mobile\:bottom-4 {
        bottom: 4rem;
    }

    .mobile\:left-4 {
        left: 4rem;
    }

    .mobile\:right-5 {
        right: 5rem;
    }

    .mobile\:top-5 {
        top: 5rem;
    }

    .mobile\:bottom-5 {
        bottom: 5rem;
    }

    .mobile\:left-5 {
        left: 5rem;
    }

    .mobile\:right-5 {
        right: 5rem;
    }

    .mobile\:top-6 {
        top: 6rem;
    }

    .mobile\:bottom-6 {
        bottom: 6rem;
    }

    .mobile\:left-6 {
        left: 6rem;
    }

    .mobile\:right-6 {
        right: 6rem;
    }

    .mobile\:top-8 {
        top: 8rem;
    }

    .mobile\:bottom-8 {
        bottom: 8rem;
    }

    .mobile\:left-8 {
        left: 8rem;
    }

    .mobile\:right-8 {
        right: 8rem;
    }

    .mobile\:top-10 {
        top: 10rem;
    }

    .mobile\:bottom-10 {
        bottom: 10rem;
    }

    .mobile\:left-10 {
        left: 10rem;
    }

    .mobile\:right-10 {
        right: 10rem;
    }

    .mobile\:left-6-n {
        left: -6rem;
    }

    .mobile\:right-6-n {
        right: -6rem;
    }

    .mobile\:left-8-n {
        left: -8rem;
    }

    .mobile\:right-8-n {
        right: -8rem;
    }

    .mobile\:left-10-n {
        left: -10rem;
    }

    .mobile\:right-10-n {
        right: -10rem;
    }

    .mobile\:left-12-n {
        left: -12rem;
    }

    .mobile\:right-12-n {
        right: -12rem;
    }

    .mobile\:left-14-n {
        left: -14rem;
    }

    .mobile\:right-14-n {
        right: -14rem;
    }


    .mobile\:left-15-n {
        left: -15rem;
    }

    .mobile\:right-15-n {
        right: -15rem;
    }

    .mobile\:left-16-n {
        left: -16rem;
    }

    .mobile\:right-16-n {
        right: -16rem;
    }

    .mobile\:left-20-n {
        left: -20rem;
    }

    .mobile\:right-20-n {
        right: -20rem;
    }


    .mobile\:top-2-n {
        top: -2rem;
    }

    .mobile\:bottom-2-n {
        bottom: -2rem;
    }


    .mobile\:top-3-n {
        top: -3rem;
    }

    .mobile\:bottom-3-n {
        bottom: -3rem;
    }


    .mobile\:top-4-n {
        top: -4rem;
    }

    .mobile\:bottom-4-n {
        bottom: -4rem;
    }


    .mobile\:top-6-n {
        top: -6rem;
    }

    .mobile\:bottom-6-n {
        bottom: -6rem;
    }

    .mobile\:top-10-n {
        top: -10rem;
    }

    .mobile\:bottom-10-n {
        bottom: -10rem;
    }

    .mobile\:top-15 {
        top: 15rem;
    }

    .mobile\:top-16 {
        top: 16rem;
    }


    /*Translate*/
    .mobile\:tx-100px {
        transform: translateX(100px);
    }

    .mobile\:tx-200px {
        transform: translateX(200px);
    }

    .mobile\:tx-300px {
        transform: translateX(300px);
    }

    .mobile\:tx-100px-n {
        transform: translateX(-100px);
    }

    .mobile\:tx-200px-n {
        transform: translateX(-200px);
    }

    .mobile\:tx-300px-n {
        transform: translateX(-200px);
    }

    /*Transition*/
    .mobile\:t-2 {
        transition: all 0.2s;
    }

    .mobile\:t-3 {
        transition: all 0.3s;
    }

    .mobile\:t-4 {
        transition: all 0.4s;
    }

    .mobile\:t-5 {
        transition: all 0.5s;
    }


    /*Zindex*/
    .mobile\:z-10 {
        z-index: 10;
    }

    .mobile\:z-20 {
        z-index: 20;
    }

    .mobile\:z-30 {
        z-index: 30;
    }

    .mobile\:z-40 {
        z-index: 40;
    }

    .mobile\:z-50 {
        z-index: 50;
    }

    .mobile\:z-60 {
        z-index: 60;
    }

    .mobile\:z-70 {
        z-index: 70;
    }

    .mobile\:z-80 {
        z-index: 80;
    }

    .mobile\:z-90 {
        z-index: 90;
    }

    .mobile\:z-100 {
        z-index: 100;
    }

    .mobile\:z-200 {
        z-index: 200;
    }

    .mobile\:z-300 {
        z-index: 300;
    }

    .mobile\:z-400 {
        z-index: 400;
    }

    .mobile\:z-500 {
        z-index: 500;
    }

    .mobile\:z-600 {
        z-index: 600;
    }

    .mobile\:z-700 {
        z-index: 700;
    }

    .mobile\:z-800 {
        z-index: 800;
    }

    .mobile\:z-900 {
        z-index: 900;
    }

    .mobile\:z-1000 {
        z-index: 1000;
    }


    /*layout flex*/
    .mobile\:align-center {
        align-items: center;
    }

    .mobile\:align-end {
        align-items: flex-end;
    }

    .mobile\:align-start {
        align-items: flex-start;
    }

    .mobile\:justify-center {
        justify-content: center;
    }

    .mobile\:justify-end {
        justify-content: flex-end;
    }

    .mobile\:justify-start {
        justify-content: flex-start;
    }

    .mobile\:grows {
        flex-grow: 1;
    }

    .mobile\:grows-0 {
        flex-grow: 0;
    }

    .mobile\:shrinks {
        flex-shrink: 1;
    }

    .mobile\:shrinks-0 {
        flex-shrink: 0;
    }

    .mobile\:column {
        flex-basis: 0;
        flex-grow: 1;
        flex-shrink: 1;
    }

    .mobile\:direction-column {
        flex-direction: column;
    }

    .mobile\:direction-row {
        flex-direction: row;
    }

    .mobile\:direction-row-reverse {
        flex-direction: row-reverse;
    }

    .mobile\:direction-column-reverse {
        flex-direction: column-reverse;
    }

    .mobile\:no-wrap {
        flex-wrap: nowrap;
    }

    .mobile\:wrap {
        flex-wrap: wrap;
    }

    .mobile\:order-1 {
        order: 1;
    }

    .mobile\:order-2 {
        order: 2;
    }

    .mobile\:order-3 {
        order: 3;
    }

    .mobile\:order-4 {
        order: 4;
    }

    .mobile\:order-5 {
        order: 5;
    }

    .mobile\:order-6 {
        order: 6;
    }

    .mobile\:order-7 {
        order: 7;
    }

    .mobile\:order-8 {
        order: 8;
    }

    .mobile\:order-9 {
        order: 9;
    }

    .mobile\:col2 {
        width: 50%;
    }

    .mobile\:col3 {
        width: 33.33%;
    }

    .mobile\:col4 {
        width: 25%;
    }

    .mobile\:col5 {
        width: 20%;
    }


    /*columns*/
    .mobile\:grid1 {
        display: grid;
        grid-template-columns: repeat(1, minmax(0, 1fr));
    }

    .mobile\:grid2 {
        display: grid;
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }

    .mobile\:grid3 {
        display: grid;
        grid-template-columns: repeat(3, minmax(0, 1fr));
    }

    .mobile\:grid4 {
        display: grid;
        grid-template-columns: repeat(4, minmax(0, 1fr));
    }

    .mobile\:grid5 {
        display: grid;
        grid-template-columns: repeat(5, minmax(0, 1fr));
    }

    .mobile\:grid6 {
        display: grid;
        grid-template-columns: repeat(6, minmax(0, 1fr));
    }

    .mobile\:grid7 {
        display: grid;
        grid-template-columns: repeat(7, minmax(0, 1fr));
    }

    .mobile\:grid8 {
        display: grid;
        grid-template-columns: repeat(8, minmax(0, 1fr));
    }

    .mobile\:row-span2 {
        grid-row-start: span 2;
    }

    .mobile\:col-span2 {
        grid-column-start: span 2;
    }

    .mobile\:col-span3 {
        grid-column-start: span 3;
    }

    .mobile\:col-span4 {
        grid-column-start: span 4;
    }

    .mobile\:col-span5 {
        grid-column-start: span 5;
    }

    .mobile\:col-span6 {
        grid-column-start: span 5;
    }

    .mobile\:col-span7 {
        grid-column-start: span 5;
    }

    .mobile\:col-span8 {
        grid-column-start: span 5;
    }

    .mobile\:col-gap-quarter {
        grid-column-gap: 0.25rem;
    }

    .mobile\:col-gap-half {
        grid-column-gap: 0.5rem;
    }

    .mobile\:col-gap {
        grid-column-gap: 1rem;
    }

    .mobile\:col-gap-2 {
        grid-column-gap: 2rem;
    }

    .mobile\:col-gap-3 {
        grid-column-gap: 3rem;
    }

    .mobile\:col-gap-4 {
        grid-column-gap: 4rem;
    }

    .mobile\:col-gap-6 {
        grid-column-gap: 6rem;
    }

    .mobile\:col-gap-8 {
        grid-column-gap: 8rem;
    }

    .mobile\:row-gap-quarter {
        grid-row-gap: 0.25rem;
    }

    .mobile\:row-gap-half {
        grid-row-gap: 0.5rem;
    }

    .mobile\:row-gap {
        grid-row-gap: 1rem;
    }

    .mobile\:row-gap-2 {
        grid-row-gap: 2rem;
    }

    .mobile\:row-gap-3 {
        grid-row-gap: 3rem;
    }

    .mobile\:row-gap-4 {
        grid-row-gap: 4rem;
    }


    /*Sizes*/
    .mobile\:min-w-2400px {
        min-width: 2400px;
    }

    .mobile\:min-w-2000px {
        min-width: 2000px;
    }

    .mobile\:min-w-1800px {
        min-width: 1800px;
    }

    .mobile\:min-w-1600px {
        min-width: 1600px;
    }

    .mobile\:min-w-1400px {
        min-width: 1400px;
    }

    .mobile\:min-w-1360px {
        min-width: 1360px;
    }

    .mobile\:min-w-1200px {
        min-width: 1200px;
    }

    .mobile\:min-w-1056px {
        min-width: 1056px;
    }

    .mobile\:min-w-940px {
        min-width: 940px;
    }

    .mobile\:min-w-800px {
        min-width: 800px;
    }

    .mobile\:min-w-700px {
        min-width: 700px;
    }

    .mobile\:min-w-600px {
        min-width: 600px;
    }

    .mobile\:min-w-400px {
        min-width: 400px;
    }

    .mobile\:min-w-300px {
        min-width: 300px;
    }

    .mobile\:min-w-200px {
        min-width: 200px;
    }

    .mobile\:min-w-100px {
        min-width: 100px;
    }

    .mobile\:min-w-0 {
        min-width: 0px;
    }

    .mobile\:min-w-100 {
        min-width: 100%;
    }

    .mobile\:min-w-90 {
        min-width: 90%;
    }

    .mobile\:min-w-80 {
        min-width: 80%;
    }

    .mobile\:min-w-70 {
        min-width: 70%;
    }

    .mobile\:min-w-60 {
        min-width: 60%;
    }

    .mobile\:min-w-50 {
        min-width: 50%;
    }


    .mobile\:max-w-2400px {
        max-width: 2400px;
    }

    .mobile\:max-w-2000px {
        max-width: 2000px;
    }

    .mobile\:max-w-1800px {
        max-width: 1800px;
    }

    .mobile\:max-w-1600px {
        max-width: 1600px;
    }

    .mobile\:max-w-1400px {
        max-width: 1400px;
    }

    .mobile\:max-w-1360px {
        max-width: 1360px;
    }

    .mobile\:max-w-1200px {
        max-width: 1200px;
    }

    .mobile\:max-w-1056px {
        max-width: 1056px;
    }

    .mobile\:max-w-940px {
        max-width: 940px;
    }

    .mobile\:max-w-800px {
        max-width: 800px;
    }

    .mobile\:max-w-700px {
        max-width: 700px;
    }

    .mobile\:max-w-600px {
        max-width: 600px;
    }

    .mobile\:max-w-500px {
        max-width: 500px;
    }

    .mobile\:max-w-400px {
        max-width: 400px;
    }

    .mobile\:max-w-350px {
        max-width: 350px;
    }

    .mobile\:max-w-300px {
        max-width: 300px;
    }

    .mobile\:max-w-200px {
        max-width: 200px;
    }

    .mobile\:max-w-100px {
        max-width: 100px;
    }

    .mobile\:max-w-100 {
        max-width: 100%;
    }

    .mobile\:max-w-90 {
        max-width: 90%;
    }

    .mobile\:max-w-80 {
        max-width: 80%;
    }

    .mobile\:max-w-70 {
        max-width: 70%;
    }

    .mobile\:max-w-60 {
        max-width: 60%;
    }

    .mobile\:max-w-50 {
        max-width: 50%;
    }

    /*Width*/
    .mobile\:w-0 {
        width: 0;
    }

    .mobile\:w-1rem {
        width: 1rem;
    }

    .mobile\:w-2rem {
        width: 2rem;
    }

    .mobile\:w-3rem {
        width: 3rem;
    }

    .mobile\:w-4rem {
        width: 4rem;
    }

    .mobile\:w-5rem {
        width: 5rem;
    }

    .mobile\:w-6rem {
        width: 6rem;
    }

    .mobile\:w-7rem {
        width: 7rem;
    }

    .mobile\:w-8rem {
        width: 8rem;
    }

    .mobile\:w-10rem {
        width: 10rem;
    }

    .mobile\:w-15rem {
        width: 15rem;
    }

    .mobile\:w-20rem {
        width: 20rem;
    }

    .mobile\:w-100 {
        width: 100%;
    }

    .mobile\:w-90 {
        width: 90%;
    }

    .mobile\:w-80 {
        width: 80%;
    }

    .mobile\:w-70 {
        width: 70%;
    }


    .mobile\:w-60 {
        width: 60%;
    }


    .mobile\:w-50 {
        width: 50%;
    }

    .mobile\:w-40 {
        width: 40%;
    }

    .mobile\:w-33 {
        width: 33.33%;
    }

    .mobile\:w-30 {
        width: 30%;
    }

    .mobile\:w-25 {
        width: 25%;
    }

    .mobile\:w-20 {
        width: 20%;
    }


    .mobile\:w-10 {
        width: 10%;
    }

    .mobile\:w-auto {
        width: auto;
    }


    .mobile\:w-10px {
        width: 10px;
    }

    .mobile\:w-20px {
        width: 20px;
    }

    .mobile\:w-30px {
        width: 30px;
    }

    .mobile\:w-40px {
        width: 40px;
    }

    .mobile\:w-50px {
        width: 50px;
    }

    .mobile\:w-60px {
        width: 60px;
    }

    .mobile\:w-70px {
        width: 70px;
    }

    .mobile\:w-80px {
        width: 80px;
    }

    .mobile\:w-90px {
        width: 90px;
    }

    .mobile\:w-100px {
        width: 100px;
    }

    .mobile\:w-150px {
        width: 150px;
    }

    .mobile\:w-200px {
        width: 200px;
    }

    .mobile\:w-250px {
        width: 250px;
    }

    .mobile\:w-300px {
        width: 300px;
    }

    .mobile\:w-350px {
        width: 350px;
    }

    .mobile\:w-400px {
        width: 400px;
    }

    .mobile\:w-500px {
        width: 500px;
    }

    .mobile\:w-600px {
        width: 600px;
    }

    .mobile\:w-800px {
        width: 800px;
    }

    .mobile\:w-1000px {
        width: 1000px;
    }


    /*height*/
    .mobile\:h-auto {
        height: auto;
    }

    .mobile\:h-halfrem {
        height: 0.5rem;
    }

    .mobile\:h-1rem {
        height: 1rem;
    }

    .mobile\:h-1halfrem {
        height: 1.5rem;
    }

    .mobile\:h-2rem {
        height: 2rem;
    }

    .mobile\:h-2halfrem {
        height: 2.5rem;
    }

    .mobile\:h-3rem {
        height: 3rem;
    }

    .mobile\:h-3halfrem {
        height: 3.5rem;
    }

    .mobile\:h-4rem {
        height: 4rem;
    }

    .mobile\:h-4halfrem {
        height: 4.5rem;
    }

    .mobile\:h-5rem {
        height: 5rem;
    }

    .mobile\:h-6rem {
        height: 6rem;
    }

    .mobile\:h-7rem {
        height: 7rem;
    }

    .mobile\:h-8rem {
        height: 8rem;
    }

    .mobile\:h-10rem {
        height: 10rem;
    }

    .mobile\:h-15rem {
        height: 15rem;
    }

    .mobile\:h-20rem {
        height: 20rem;
    }

    .mobile\:h-10vh {
        height: 10vh;
    }

    .mobile\:h-20vh {
        height: 20vh;
    }

    .mobile\:h-30vh {
        height: 30vh;
    }

    .mobile\:h-40vh {
        height: 40vh;
    }

    .mobile\:h-50vh {
        height: 50vh;
    }

    .mobile\:h-60vh {
        height: 60vh;
    }

    .mobile\:h-70vh {
        height: 70vh;
    }

    .mobile\:h-80vh {
        height: 80vh;
    }

    .mobile\:h-90vh {
        height: 90vh;
    }

    .mobile\:h-100vh {
        height: 100vh;
    }

    .mobile\:h-10 {
        height: 10%;
    }

    .mobile\:h-20 {
        height: 20%;
    }

    .mobile\:h-30 {
        height: 30%;
    }

    .mobile\:h-40 {
        height: 40%;
    }

    .mobile\:h-50 {
        height: 50%;
    }

    .mobile\:h-60 {
        height: 60%;
    }

    .mobile\:h-100 {
        height: 100%;
    }

    .mobile\:h-110 {
        height: 110%;
    }

    .mobile\:h-120 {
        height: 120%;
    }

    .mobile\:h-130 {
        height: 130%;
    }

    .mobile\:h-140 {
        height: 140%;
    }

    .mobile\:h-150 {
        height: 150%;
    }

    .mobile\:h-100px {
        height: 100px;
    }

    .mobile\:h-150px {
        height: 150px;
    }

    .mobile\:h-200px {
        height: 200px;
    }

    .mobile\:h-250px {
        height: 250px;
    }

    .mobile\:h-300px {
        height: 300px;
    }

    .mobile\:h-400px {
        height: 400px;
    }

    .mobile\:h-500px {
        height: 500px;
    }

    .mobile\:h-600px {
        height: 600px;
    }

    .mobile\:h-800px {
        height: 800px;
    }

    .mobile\:h-1000px {
        height: 1000px;
    }


    .mobile\:min-h-0 {
        min-height: 0;
    }

    .mobile\:min-h-100vh {
        min-height: 100vh;
    }

    .mobile\:min-h-90vh {
        min-height: 90vh;
    }

    .mobile\:min-h-80vh {
        min-height: 80vh;
    }

    .mobile\:min-h-70vh {
        min-height: 70vh;
    }

    .mobile\:min-h-60vh {
        min-height: 60vh;
    }

    .mobile\:min-h-50vh {
        min-height: 50vh;
    }

    .mobile\:min-h-40vh {
        min-height: 40vh;
    }

    .mobile\:min-h-30vh {
        min-height: 30vh;
    }

    .mobile\:min-h-20vh {
        min-height: 20vh;
    }

    .mobile\:min-h-10vh {
        min-height: 10vh;
    }


    .mobile\:min-h-100px {
        min-height: 100px;
    }

    .mobile\:min-h-200px {
        min-height: 200px;
    }

    .mobile\:min-h-300px {
        min-height: 300px;
    }

    .mobile\:min-h-400px {
        min-height: 400px;
    }

    .mobile\:min-h-500px {
        min-height: 500px;
    }

    .mobile\:min-h-600px {
        min-height: 600px;
    }

    .mobile\:min-h-700px {
        min-height: 700px;
    }

    .mobile\:min-h-800px {
        min-height: 800px;
    }


    .mobile\:max-h-0 {
        max-height: 0;
    }

    .mobile\:max-h-100vh {
        max-height: 100vh;
    }

    .mobile\:max-h-90vh {
        max-height: 90vh;
    }

    .mobile\:max-h-80vh {
        max-height: 80vh;
    }

    .mobile\:max-h-70vh {
        max-height: 70vh;
    }

    .mobile\:max-h-60vh {
        max-height: 60vh;
    }

    .mobile\:max-h-50vh {
        max-height: 50vh;
    }

    .mobile\:max-h-40vh {
        max-height: 40vh;
    }

    .mobile\:max-h-30vh {
        max-height: 30vh;
    }

    .mobile\:max-h-20vh {
        max-height: 20vh;
    }

    .mobile\:max-h-10vh {
        max-height: 10vh;
    }


    .mobile\:max-h-100px {
        max-height: 100px;
    }

    .mobile\:max-h-200px {
        max-height: 200px;
    }

    .mobile\:max-h-300px {
        min-height: 300px;
    }

    .mobile\:max-h-400px {
        min-height: 400px;
    }

    .mobile\:max-h-500px {
        max-height: 500px;
    }

    .mobile\:max-h-600px {
        max-height: 600px;
    }

    .mobile\:max-h-700px {
        max-height: 700px;
    }

    .mobile\:max-h-800px {
        max-height: 800px;
    }


    .mobile\:sq-1rem {
        width: 1rem;
        height: 1rem;
    }

    .mobile\:sq-1halfrem {
        width: 1.5rem;
        height: 1.5rem;
    }

    .mobile\:sq-2rem {
        width: 2rem;
        height: 2rem;
    }

    .mobile\:sq-2halfrem {
        width: 2.5rem;
        height: 2.5rem;
    }

    .mobile\:sq-3rem {
        width: 3rem;
        height: 3rem;
    }

    .mobile\:sq-3halfrem {
        width: 3.5rem;
        height: 3.5rem;
    }


    .mobile\:sq-4rem {
        width: 4rem;
        height: 4rem;
    }

    .mobile\:sq-4halfrem {
        width: 4.5rem;
        height: 4.5rem;
    }


    .mobile\:sq-5rem {
        width: 5rem;
        height: 5rem;
    }

    .mobile\:sq-6rem {
        width: 6rem;
        height: 6rem;
    }

    .mobile\:sq-7rem {
        width: 7rem;
        height: 7rem;
    }

    .mobile\:sq-8rem {
        width: 8rem;
        height: 8rem;
    }

    .mobile\:sq-10rem {
        width: 10rem;
        height: 10rem;
    }

    .mobile\:sq-15rem {
        width: 15rem;
        height: 15rem;
    }

    .mobile\:sq-20rem {
        width: 20rem;
        height: 20rem;
    }

    /*Overflows*/
    .mobile\:overflow-y-scroll {
        overflow-y: scroll;
    }

    .mobile\:overflow-y-auto {
        overflow-y: auto;
    }

    .mobile\:overflow-x-scroll {
        overflow-x: scroll;
    }

    .mobile\:overflow-x-hidden {
        overflow-x: hidden;
    }

    .mobile\:overflow-y-auto {
        overflow-x: auto;
    }

    .mobile\:overflow-hidden {
        overflow: hidden;
    }

    /*Margins*/
    .mobile\:m-0 {
        margin: 0;
    }

    .mobile\:m-auto {
        margin: auto;
    }

    .mobile\:mver-auto {
        margin-top: auto;
        margin-bottom: auto;
    }

    .mobile\:mhor-auto {
        margin-right: auto;
        margin-left: auto;
    }

    .mobile\:m-0 {
        margin: 0;
    }

    .mobile\:ml-0 {
        margin-left: 0;
    }

    .mobile\:mr-0 {
        margin-right: 0;
    }

    .mobile\:mt-0 {
        margin-top: 0;
    }

    .mobile\:mb-0 {
        margin-bottom: 0;
    }

    .mobile\:m {
        margin: 1rem;
    }

    .mobile\:ml {
        margin-left: 1rem;
    }

    .mobile\:mr {
        margin-right: 1rem;
    }

    .mobile\:mb, .mobile\:childs-mb > * {
        margin-bottom: 1rem;
    }

    .mobile\:mt {
        margin-top: 1rem;
    }

    .mobile\:mv {
        margin-bottom: 1rem;
        margin-top: 1rem;
    }

    .mobile\:mhor {
        margin-right: 1rem;
        margin-left: 1rem;
    }

    .mobile\:mver {
        margin-top: 1rem;
        margin-bottom: 1rem;
    }

    .mobile\:m-half {
        margin: 0.5rem;
    }

    .mobile\:ml-half {
        margin-left: 0.5rem;
    }

    .mobile\:mr-half {
        margin-right: 0.5rem;
    }

    .mobile\:mb-half, .mobile\:childs-mb-half > * {
        margin-bottom: 0.5rem;
    }

    .mobile\:mt-half {
        margin-top: 0.5rem;
    }

    .mobile\:mv-half {
        margin-bottom: 0.5rem;
        margin-top: 0.5rem;
    }

    .mobile\:mhor-half {
        margin-right: 0.5rem;
        margin-left: 0.5rem;
    }


    .mobile\:m-quarter {
        margin: 0.25rem;
    }

    .mobile\:ml-quarter {
        margin-left: 0.25rem;
    }

    .mobile\:mr-quarter {
        margin-right: 0.25rem;
    }

    .mobile\:mb-quarter {
        margin-bottom: 0.25rem;
    }

    .mobile\:mt-quarter {
        margin-top: 0.25rem;
    }

    .mobile\:mver-quarter {
        margin-bottom: 0.25rem;
        margin-top: 0.25rem;
    }

    .mobile\:mhor-quarter {
        margin-right: 0.25rem;
        margin-left: 0.25rem;
    }


    .mobile\:m-2 {
        margin: 2rem;
    }

    .mobile\:ml-2 {
        margin-left: 2rem;
    }

    .mobile\:mr-2 {
        margin-right: 2rem;
    }

    .mobile\:mb-2 {
        margin-bottom: 2rem;
    }

    .mobile\:mt-2 {
        margin-top: 2rem;
    }

    .mobile\:mver-2 {
        margin-bottom: 2rem;
        margin-top: 2rem;
    }

    .mobile\:mhor-2 {
        margin-right: 2rem;
        margin-left: 2rem;
    }

    .mobile\:m-3 {
        margin: 3rem;
    }

    .mobile\:ml-3 {
        margin-left: 3rem;
    }

    .mobile\:mr-3 {
        margin-right: 3rem;
    }

    .mobile\:mb-3 {
        margin-bottom: 3rem;
    }

    .mobile\:mb-3-n {
        margin-bottom: -3rem;
    }

    .mobile\:mt-3 {
        margin-top: 3rem;
    }

    .mobile\:mver-3 {
        margin-bottom: 3rem;
        margin-top: 3rem;
    }

    .mobile\:mhor-3 {
        margin-right: 3rem;
        margin-left: 3rem;
    }

    .mobile\:m-4 {
        margin: 4rem;
    }

    .mobile\:ml-4 {
        margin-left: 4rem;
    }

    .mobile\:ml-6 {
        margin-left: 6rem;
    }

    .mobile\:ml-8 {
        margin-left: 8rem;
    }

    .mobile\:ml-10 {
        margin-left: 10rem;
    }

    .mobile\:mr-4 {
        margin-right: 4rem;
    }

    .mobile\:mr-6 {
        margin-right: 6rem;
    }

    .mobile\:mr-8 {
        margin-right: 8rem;
    }

    .mobile\:mb-4 {
        margin-bottom: 4rem;
    }


    .mobile\:mb-6 {
        margin-bottom: 6rem;
    }

    .mobile\:mb-8 {
        margin-bottom: 8rem;
    }

    .mobile\:mb-10 {
        margin-bottom: 10rem;
    }

    .mobile\:mt-4 {
        margin-top: 4rem;
    }


    .mobile\:mt-6 {
        margin-top: 6rem;
    }


    .mobile\:mt-8 {
        margin-top: 8rem;
    }


    .mobile\:mt-10 {
        margin-top: 10rem;
    }

    .mobile\:mver-4 {
        margin-bottom: 4rem;
        margin-top: 4rem;
    }

    .mobile\:mhor-4 {
        margin-right: 4rem;
        margin-left: 4rem;
    }

    /*Padding*/
    .mobile\:p-0 {
        padding: 0;
    }

    .mobile\:pl-0 {
        padding-left: 0;
    }

    .mobile\:pr-0 {
        padding-right: 0;
    }

    .mobile\:pb-0 {
        padding-bottom: 0;
    }

    .mobile\:pt-0 {
        padding-top: 0;
    }


    .mobile\:p {
        padding: 1rem;
    }

    .mobile\:pl {
        padding-left: 1rem;
    }

    .mobile\:pr {
        padding-right: 1rem;
    }

    .mobile\:pb {
        padding-bottom: 1rem;
    }

    .mobile\:pt {
        padding-top: 1rem;
    }

    .mobile\:pver-0 {
        padding-bottom: 0rem;
        padding-top: 0rem;
    }

    .mobile\:pver, .mobile\:childs-pver > * {
        padding-bottom: 1rem;
        padding-top: 1rem;
    }

    .mobile\:phor-0 {
        padding-right: 0rem;
        padding-left: 0rem;
    }

    .mobile\:phor {
        padding-right: 1rem;
        padding-left: 1rem;
    }

    .mobile\:p-half {
        padding: 0.5rem;
    }

    .mobile\:pl-half {
        padding-left: 0.5rem;
    }

    .mobile\:pr-half {
        padding-right: 0.5rem;
    }

    .mobile\:pb-half {
        padding-bottom: 0.5rem;
    }

    .mobile\:pt-half {
        padding-top: 0.5rem;
    }

    .mobile\:pver-half, .mobile\:childs-pver-half > * {
        padding-bottom: 0.5rem;
        padding-top: 0.5rem;
    }

    .mobile\:phor-half {
        padding-right: 0.5rem;
        padding-left: 0.5rem;
    }


    .mobile\:p-quarter {
        padding: 0.25rem;
    }

    .mobile\:pl-quarter {
        padding-left: 0.25rem;
    }

    .mobile\:pr-quarter {
        padding-right: 0.25rem;
    }

    .mobile\:pb-quarter {
        padding-bottom: 0.25rem;
    }

    .mobile\:pt-quarter {
        padding-top: 0.25rem;
    }

    .mobile\:pver-quarter, .mobile\:childs-pver-quarter > * {
        padding-bottom: 0.25rem;
        padding-top: 0.25rem;
    }

    .mobile\:phor-quarter {
        padding-right: 0.25rem;
        padding-left: 0.25rem;
    }


    .mobile\:p-2 {
        padding: 2rem;
    }

    .mobile\:pl-2 {
        padding-left: 2rem;
    }

    .mobile\:pr-2 {
        padding-right: 2rem;
    }

    .mobile\:pb-2 {
        padding-bottom: 2rem;
    }

    .mobile\:pt-2 {
        padding-top: 2rem;
    }

    .mobile\:pver-2 {
        padding-bottom: 2rem;
        padding-top: 2rem;
    }

    .mobile\:phor-2 {
        padding-right: 2rem;
        padding-left: 2rem;
    }

    .mobile\:p-3 {
        padding: 3rem;
    }

    .mobile\:pl-3 {
        padding-left: 3rem;
    }

    .mobile\:pr-3 {
        padding-right: 3rem;
    }

    .mobile\:pb-3 {
        padding-bottom: 3rem;
    }

    .mobile\:pt-3 {
        padding-top: 3rem;
    }

    .mobile\:pver-3 {
        padding-bottom: 3rem;
        padding-top: 3rem;
    }

    .mobile\:phor-3 {
        padding-right: 3rem;
        padding-left: 3rem;
    }

    .mobile\:p-4 {
        padding: 4rem;
    }

    .mobile\:pl-4 {
        padding-left: 4rem;
    }

    .mobile\:pr-4 {
        padding-right: 4rem;
    }

    .mobile\:pb-4 {
        padding-bottom: 4rem;
    }

    .mobile\:pt-4 {
        padding-top: 4rem;
    }

    .mobile\:pver-4 {
        padding-bottom: 4rem;
        padding-top: 4rem;
    }

    .mobile\:phor-4 {
        padding-right: 4rem;
        padding-left: 4rem;
    }

    .mobile\:p-6 {
        padding: 6rem;
    }

    .mobile\:pl-6 {
        padding-left: 6rem;
    }

    .mobile\:pr-6 {
        padding-right: 6rem;
    }

    .mobile\:pb-6 {
        padding-bottom: 6rem;
    }

    .mobile\:pt-6 {
        padding-top: 6rem;
    }

    .mobile\:pver-6 {
        padding-bottom: 6rem;
        padding-top: 6rem;
    }

    .mobile\:phor-6 {
        padding-right: 6rem;
        padding-left: 6rem;
    }

    .mobile\:p-8 {
        padding: 8rem;
    }

    .mobile\:pl-8 {
        padding-left: 8rem;
    }

    .mobile\:pr-8 {
        padding-right: 8rem;
    }

    .mobile\:pb-8 {
        padding-bottom: 8rem;
    }

    .mobile\:pt-8 {
        padding-top: 8rem;
    }

    .mobile\:pver-8 {
        padding-bottom: 8rem;
        padding-top: 8rem;
    }

    .mobile\:phor-8 {
        padding-right: 8rem;
        padding-left: 8rem;
    }

    .mobile\:pb-100 {
        padding-bottom: 100%;
    }


    /*objects*/
    .mobile\:obj-cover {
        object-fit: cover;
    }

    .mobile\:obj-contain {
        object-fit: contain;
    }

    .mobile\:obj-top {
        object-position: top;
    }

    .mobile\:obj-bottom {
        object-position: bottom;
    }

    .mobile\:obj-left {
        object-position: left;
    }

    .mobile\:obj-top-left {
        object-position: left top;
    }

    .mobile\:obj-right {
        object-position: right;
    }

    .mobile\:bg-contain {
        background-size: contain;
    }

    .mobile\:bg-no-repeat {
        background-repeat: no-repeat;
    }

    .mobile\:bg-repeat {
        background-repeat: repeat;
    }

    .mobile\:bg-cover {
        background-size: cover;
    }

    .mobile\:bg-top {
        background-position: top;
    }

    .mobile\:bg-bottom {
        background-position: bottom;
    }

    .mobile\:bg-left {
        background-position: left;
    }

    .mobile\:bg-right {
        background-position: right;
    }

    .mobile\:bg-center {
        background-position: center;
    }


    /*Corners*/
    .mobile\:br-0 {
        border-radius: 0rem;
    }

    .mobile\:br-quarter {
        border-radius: 0.25rem;
    }

    .mobile\:br-half {
        border-radius: 0.5rem;
    }

    .mobile\:br-1half {
        border-radius: 1.5rem;
    }

    .mobile\:br {
        border-radius: 1rem;
    }

    .mobile\:br-2 {
        border-radius: 2rem;
    }

    .mobile\:br-2half {
        border-radius: 2.5rem;
    }

    .mobile\:br-3 {
        border-radius: 3rem;
    }

    .mobile\:br-4 {
        border-radius: 4rem;
    }

    .mobile\:br-8 {
        border-radius: 8rem;
    }

    .mobile\:br-50 {
        border-radius: 50%;
    }

    /*borders*/
    .mobile\:b-0 {
        border: 0px;
    }

    .mobile\:b-top-0 {
        border-top: 0px solid;
    }

    .mobile\:b-right-0 {
        border-right: 0px solid;
    }

    .mobile\:b-bottom-0 {
        border-bottom: 0px solid;
    }

    .mobile\:b-left-0 {
        border-left: 0px solid;
    }

    .mobile\:b {
        border: 1px solid;
    }

    .mobile\:b-right {
        border-right: 1px solid;
    }

    .mobile\:b-bottom {
        border-bottom: 1px solid;
    }

    .mobile\:b-left {
        border-left: 1px solid;
    }

    .mobile\:b-top {
        border-top: 1px solid;
    }

    .mobile\:b-2 {
        border: 2px solid;
    }

    .mobile\:b-right-2 {
        border-right: 2px solid;
    }

    .mobile\:b-bottom-2 {
        border-bottom: 2px solid;
    }

    .mobile\:b-left-2 {
        border-left: 2px solid;
    }

    .mobile\:b-top-2 {
        border-top: 2px solid;
    }


    .mobile\:b-3 {
        border: 3px solid;
    }

    .mobile\:b-right-3 {
        border-right: 3px solid;
    }

    .mobile\:b-bottom-3 {
        border-bottom: 3px solid;
    }

    .mobile\:b-left-3 {
        border-left: 3px solid;
    }

    .mobile\:b-top-3 {
        border-top: 3px solid;
    }

    .mobile\:b-4 {
        border: 4px solid;
    }

    .mobile\:b-right-4 {
        border-right: 4px solid;
    }

    .mobile\:b-bottom-4 {
        border-bottom: 4px solid;
    }

    .mobile\:b-left-4 {
        border-left: 4px solid;
    }

    .mobile\:b-top-4 {
        border-top: 4px solid;
    }

    .mobile\:b-5 {
        border: 5px solid;
    }

    .mobile\:b-right-5 {
        border-right: 5px solid;
    }

    .mobile\:b-bottom-5 {
        border-bottom: 5px solid;
    }

    .mobile\:b-left-5 {
        border-left: 5px solid;
    }

    .mobile\:b-top-5 {
        border-top: 5px solid;
    }

    .mobile\:focus\:outline-0 {
        outline: 0;
    }

    /*Opacity*/
    .mobile\:op-0, .mobile\:hover\:op-0:hover {
        opacity: 0;
    }

    .mobile\:op-10, .mobile\:hover\:op-10:hover {
        opacity: .1;
    }

    .mobile\:op-20, .mobile\:hover\:op-20:hover {
        opacity: .20;
    }

    .mobile\:op-30, .mobile\:hover\:op-30:hover {
        opacity: .30;
    }

    .mobile\:op-40, .mobile\:hover\:op-40:hover {
        opacity: .40;
    }

    .mobile\:op-50, .mobile\:hover\:op-50:hover {
        opacity: .5;
    }

    .mobile\:op-60, .mobile\:hover\:op-60:hover {
        opacity: .60;
    }

    .mobile\:op-70, .mobile\:hover\:op-70:hover {
        opacity: .70;
    }

    .mobile\:op-80, .mobile\:hover\:op-80:hover {
        opacity: .80;
    }

    .mobile\:op-90, .mobile\:hover\:op-90:hover {
        opacity: .90;
    }

    .mobile\:op-100, .mobile\:hover\:op-100:hover {
        opacity: 1;
    }


    .mobile\:transition-3 {
        transition: all 0.3s;
    }

    .mobile\:transition-4 {
        transition: all 0.4s;
    }

    .mobile\:transition-5 {
        transition: all 0.5s;
    }

    .mobile\:transition-6 {
        transition: all 0.6s;
    }

    .mobile\:cursor-pointer {
        cursor: pointer;
    }

    .mobile\:list-none {
        list-style: none;
    }

    .mobile\:no-wrap {
        white-space: nowrap;
    }

    .mobile\:break-word {
        overflow-wrap: break-word;
        word-wrap: break-word;
        -webkit-hyphens: auto;
            -ms-hyphens: auto;
                hyphens: auto;
    }


    .mobile\:scale-1-1, .mobile\:hover\:scale-1-1:hover {
        transform: scale(1.1);
    }

    .mobile\:scale-1-2, .mobile\:hover\:scale-1-2:hover {
        transform: scale(1.2);
    }


    .mobile\:translate-y, .mobile\:hover\:translate-y:hover {
        transform: translateY(1rem);
    }

    .mobile\:translate-y-half, .mobile\:hover\:translate-y-half:hover {
        transform: translateY(0.5rem);
    }


    .mobile\:translate-y-n, .mobile\:hover\:translate-y-n:hover {
        transform: translateY(-1rem);
    }

    .mobile\:translate-y-half-n, .mobile\:hover\:translate-y-half-n:hover {
        transform: translateY(-0.5rem);
    }

}

